import axios from "axios";

const tokenPairInfo = async (chain, tokenAddress, priceOn, api) => {
  console.log("gettingPairData");
  var query;
  var filteredData;
  var res;
  switch (chain) {
    //FIXME ftm chain ID
    case 4002:
      //   query = `https://api.dexscreener.io/latest/dex/search?q=${tokenAddress}`;
      query = `https://api.ftmscan.com/api?module=token&action=tokeninfo&contractaddress=${tokenAddress}&apikey=${api}`;
      // setTokenNative("FTM");
      res = await axios(query);
      break;
    case 250:
      //   query = `https://api.dexscreener.io/latest/dex/search?q=${tokenAddress}`;
      query = `https://api.ftmscan.com/api?module=token&action=tokeninfo&contractaddress=${tokenAddress}&apikey=${api}`;
      // setTokenNative("FTM");
      res = await axios(query);
      break;
    default:
      query = ``;
  }
  //   console.log(res);
  return res;
};

const filterByDex = (obj) => {
  var dex = "spookyswap";
  if (
    obj.chainId === "fantom" &&
    obj.dexId === dex
    //   obj.baseToken.address === tokenSymbol
    // obj.pairAddress === "0x90469ACbC4b6d877873CD4f1CCA54fDE8075A998"
    //TODO some work left find right dex pairing
  ) {
    return true;
  } else return false;
};

export default {
  tokenPairInfo: tokenPairInfo,
  filterByDex: filterByDex,
};
