import "../styles/base.css";
import "../styles/menu.css";
import Stake from "../components/stake.js";
import { Button, Alert } from "reactstrap";
import { UseContractInfo } from "../web3/ContractContext";
import React, { useState, useEffect, useContext } from "react";
import { Spinner } from "reactstrap";

export function GENStaking() {
  const {
    isConnected,
    getGENBalance,
    genBalance,
    genUserInfo,
    getGENStakingData,
    genStakingStatus,
    handleStakeGen,
    handleUnStakeGen,
    handleApproveGen,
  } = UseContractInfo();

  const [trxnSucess, setTrxnSucess] = useState(null);
  const [gettingReceipt, setGettingReceipt] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleGENStaking = async (amount) => {
    setTrxnSucess(null);
    setGettingReceipt(true);
    const { success, message } = await handleStakeGen(amount);
    console.log(success, message);
    setTrxnSucess(success);
    setErrorMessage(message);
    setGettingReceipt(false);
  };

  const handleGENUnStaking = async (amount) => {
    setTrxnSucess(null);
    setGettingReceipt(true);
    const { success, message } = await handleUnStakeGen(amount);
    console.log(success, message);
    setTrxnSucess(success);
    setErrorMessage(message);
    setGettingReceipt(false);
  };

  const handleGENApprove = async (amount) => {
    setTrxnSucess(null);
    setGettingReceipt(true);
    const { success, message } = await handleApproveGen(amount);
    console.log(success, message);
    setTrxnSucess(success);
    setErrorMessage(message);
    setGettingReceipt(false);
  };

  useEffect(() => {
    if (trxnSucess === true) {
      const getData = async () => {
        await getGENStakingData();
        await getGENBalance();
      };
      getData();
    }
  }, [trxnSucess]);

  return (
    <div className="section-earn-detail" name="401staking">
      <h3 className="heading align-self-center double-line">
        GEN Staking Plan
      </h3>

      <div className="sub-section group justify-evenly">
        <div className="my-align-center">
          <h4 className="light">Daily APR</h4>
          <h5 className="highlight">
            {genStakingStatus && genStakingStatus.dailyAPR.toFixed(2)} %
          </h5>
        </div>
        <div className="my-align-center">
          <h4 className="light">APR</h4>
          <h5 className="highlight">
            {genStakingStatus && (genStakingStatus.dailyAPR * 30).toFixed(2)}%
          </h5>
        </div>
        <div className="my-align-center">
          <h4 className="light">Total Staked</h4>
          <h5 className="highlight">
            {genStakingStatus && genStakingStatus.activePool.totalToken} GEN
          </h5>
          {/* <h5 className="highlight">$$$$</h5> */}
        </div>
      </div>
      <div className="sub-section">
        <Stake
          stakingToken="GEN"
          stakingStatus={genStakingStatus}
          userStakeStatus={genUserInfo}
          handleStake={handleGENStaking}
          handleUnStake={handleGENUnStaking}
          handleApprove={handleGENApprove}
        />
        {gettingReceipt && (
          <>
            <div className="my-align-center">
              <Spinner className="text-center" type="border" color="info">
                {" "}
              </Spinner>
            </div>
          </>
        )}
        {trxnSucess !== null && (
          <Alert className="text-center" color={trxnSucess ? "info" : "danger"}>
            {errorMessage}
          </Alert>
        )}
      </div>
      <div className="sub-section text-center">
        <p>
          Note: For Metamask user, if you have problems with staking (for
          subsequent times), approve again
        </p>
        <p className="text-danger">
          Warning: Withdrawing will forfeit your rewards
        </p>
      </div>
      <div className="sub-section">
        <div className="row justify-between light">
          <div className="text-left">
            <p>Your Balance</p>
            <p>Your Staked Balance</p>
            <p>GEN reward accrued</p>
            <p>Pending GEN Reward</p>
            <p>USDC reward accrued</p>
            <p>Pending USDC Reward</p>
            <p>Time until tax exempt</p>
            <p>Withdraw tax</p>
          </div>
          <div className="text-right">
            <p>{genBalance.toFixed(2)} GEN</p>
            <p>{genUserInfo && genUserInfo.stakedBalance.toFixed(2)} veGEN</p>
            <p>{genUserInfo && genUserInfo.rewardDebt.toFixed(2)} GEN</p>
            <p>{genUserInfo && genUserInfo.pendingGEN.toFixed(2)} GEN</p>
            <p>{genUserInfo && genUserInfo.USDCrewardDebt.toFixed(2)} USDC</p>
            <p>{genUserInfo && genUserInfo.pendingUSDC.toFixed(2)} USDC</p>
            <p>{genUserInfo && genUserInfo.withdrawTime.toFixed(2)} days</p>
            <p>{genUserInfo && genUserInfo.withdrawTax} %</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default GENStaking;
