import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React, { useState, useEffect, useContext, useCallback } from "react";

import Head from "./components/Head";
import Landing from "./pages/Landing";
import Home from "./pages/Home";

import { Web3ModalProvider } from "./web3/web3Modal";
import { ContractContext } from "./web3/ContractContext";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// 0. Setup queryClient
const queryClient = new QueryClient();

function App() {
  const [ready, setReady] = useState(false);
  // const { theme, setTheme } = useWeb3ModalTheme();

  useEffect(() => {
    setReady(true);
  }, []);

  return (
    <>
      {ready ? (
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={true} />
          <Web3ModalProvider>
            <ContractContext>
              <Router>
                <Head />
                <Routes>
                  <Route path="/home" element={<Landing />} />
                  <Route path="*" element={<Home />} />
                </Routes>
              </Router>
            </ContractContext>
          </Web3ModalProvider>
        </QueryClientProvider>
      ) : null}
    </>
  );
}

export default App;
