import "../styles/base.css";
import "../styles/sections.css";
import { Button, InputGroup, Input, Label } from "reactstrap";
import { useState } from "react";
import { UseContractInfo } from "../web3/ContractContext";
import { FaExternalLinkAlt } from "react-icons/fa";

export function Redeem(props) {
  const { isConnected, genBalance } = UseContractInfo();

  const [selected, setSelected] = useState("mint");
  const [genAmount, setGENAmount] = useState(0);
  const [agenAmount, setAGENAmount] = useState(0);

  const setMaxAmount = async () => {
    if (selected === "mint") {
      setGENAmount(genBalance);
    } else {
      setAGENAmount(props.aGENStatus.userBalance);
    }
  };
  return (
    <>
      {/* <div className="row"> */}
      <div className="group mb-3">
        <Button
          outline
          color=""
          className={selected === "mint" ? "btn-underline" : "btn-text"}
          size="lg"
          onClick={() => {
            setSelected("mint");
          }}
        >
          MINT
        </Button>
        <Button
          outline
          color=""
          className={selected === "redeem" ? "btn-underline" : "btn-text"}
          size="lg"
          onClick={() => {
            setSelected("redeem");
          }}
        >
          REDEEM
        </Button>
      </div>
      <Label>GEN</Label>
      <div className="group mb-3">
        <InputGroup
          className={
            !isConnected || selected === "redeem"
              ? "input-group disabled"
              : "input-group"
          }
        >
          <Input
            readOnly={selected === "redeem" ? true : false}
            id="mintNumber"
            name="number"
            placeholder="0"
            type="number"
            className="input-number"
            style={{ marginRight: 0, backgroundColor: "transparent" }}
            value={genAmount}
            onChange={(event) => {
              console.log(event.target.value);
              setGENAmount(event.target.value);
            }}
          />
          <Button
            color=""
            id="max"
            style={{ borderRadius: 0, color: "black" }}
            disabled={!isConnected}
            onClick={() => {
              setMaxAmount();
            }}
          >
            Max
          </Button>
        </InputGroup>
      </div>
      <p className="text-right">GEN Balance: {genBalance.toFixed(2)}</p>
      <Label>aGEN</Label>
      <div className="group mb-3">
        <InputGroup
          className={
            !isConnected || selected === "mint"
              ? "input-group disabled"
              : "input-group"
          }
        >
          <Input
            readOnly={selected === "mint" ? true : false}
            id="redeemNumber"
            name="number"
            placeholder="0"
            type="number"
            className="input-number"
            style={{ marginRight: 0, backgroundColor: "transparent" }}
            value={agenAmount}
            disabled={!isConnected}
            onChange={(event) => {
              console.log(event.target.value);
              setAGENAmount(event.target.value);
            }}
          />
          <Button
            color=""
            id="max"
            style={{ borderRadius: 0, color: "black" }}
            disabled={!isConnected}
            onClick={() => {
              setMaxAmount();
            }}
          >
            Max
          </Button>
        </InputGroup>
      </div>
      <p className="text-right">
        aGEN Balance:{" "}
        {props.aGENStatus && props.aGENStatus.userBalance.toFixed(2)}
      </p>

      {/* </div> */}
      <div className="group mt-5 mb-3">
        {selected === "mint" ? (
          <Button
            outline
            color=""
            className="btn-outline"
            size="lg"
            disabled={!isConnected || !props.aGENStatus}
            onClick={() => {
              if (genAmount > 0) props.handleMint(genAmount);
            }}
          >
            Mint
          </Button>
        ) : (
          <Button
            outline
            color=""
            className="btn-outline"
            size="lg"
            disabled={!isConnected}
            onClick={() => {
              if (agenAmount > 0) props.handleRedeem(agenAmount);
            }}
          >
            Redeem
          </Button>
        )}

        <Button outline color="" className="btn-outline" size="lg">
          Buy aGEN on dex
        </Button>
      </div>
      <div className="group mt-3 mb-3">
        <Button
          outline
          color=""
          className="btn-outline"
          size="lg"
          style={{ width: "100%" }}
          disabled={!isConnected || !props.aGENStatus}
          onClick={() => {
            if (selected === "mint") {
              if (genAmount > 0) props.handleGENApprove(genAmount);
            } else {
              // if (agenAmount > 0) props.handleAGENApprove(agenAmount);
            }
          }}
        >
          Approve <FaExternalLinkAlt className="ml-3" />
        </Button>
      </div>
    </>
  );
}
export default Redeem;
