import React, { useState, useEffect, useContext, useReducer } from "react";

import {
  exchangeProxy,
  FTM_API_KEY,
  hardhat_USDC_ADDRESS,
  ABI_ERC20,
  hardhat_LP_ADDRESS,
  LP_ABI,
  hardhat_OLD_GEN_ADDRESS,
  hardhat_GEN_ADDRESS,
  hardhat_GEN_STAKING_ADDRESS,
  hardhat_LP_STAKING_ADDRESS,
  hardhat_USDC_BOND_ADDRESS,
  hardhat_LP_BOND_ADDRESS,
  hardhat_aGEN_ADDRESS,
  test_USDC_ADDRESS,
  test_OLD_GEN_ADDRESS,
  test_LP_ADDRESS,
  test_GEN_ADDRESS,
  test_GEN_STAKING_ADDRESS,
  test_LP_STAKING_ADDRESS,
  test_USDC_BOND_ADDRESS,
  test_LP_BOND_ADDRESS,
  test_aGEN_ADDRESS,
  USDC_ADDRESS,
  OLD_GEN_ADDRESS,
  GEN_ADDRESS,
  GEN_STAKING_ADDRESS,
  LP_STAKING_ADDRESS,
  USDC_BOND_ADDRESS,
  LP_BOND_ADDRESS,
  aGEN_ADDRESS,
  GEN_ABI,
  GEN_STAKING_ABI,
  LP_STAKING_ABI,
  USDC_BOND_ABI,
  LP_BOND_ABI,
  aGEN_ABI,
} from "./config";
import key from "../utils/key";
import price from "./price";

import { ethers, BrowserProvider, Transaction } from "ethers";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { useQuery } from "@tanstack/react-query";

export const InfoContract = React.createContext();
// hook
export function UseContractInfo() {
  return useContext(InfoContract);
}

export function ContractContext({ children }) {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [wrongNetwork, setWrongNetwork] = useState(true);

  const { walletProvider } = useWeb3ModalProvider();
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [currAddress, setCurrAddress] = useState(null);

  const [initiated, setInitiated] = useState(false);
  const [checkPrice, setCheckPrice] = useState(false);

  const [oldGENContract, setOldGENContract] = useState(null);
  const [oldGENStatus, setOldGENStatus] = useState(null);

  const [genPrice, setGENPrice] = useState(1.38);
  const [genContract, setGENContract] = useState(null);
  const [genBalance, setGenBalance] = useState(0);
  const [lpContract, setLPContract] = useState(null);
  const [genftmLPBalance, setGenFtmLPBalanceBalance] = useState(0);
  const [usdcContract, setUSDCContract] = useState(null);
  const [usdcBalance, setUSDCBalance] = useState(0);

  const [genStakingContract, setGENStakingContract] = useState(null);
  const [genUserInfo, setGenUserInfo] = useState(null);
  const [genStakingStatus, setGENStakingStatus] = useState(null);
  const [activeGENPool, setActiveGENPool] = useState(null);

  const [lpStakingContract, setLPStakingContract] = useState(null);
  const [lpUserInfo, setLPUserInfo] = useState(null);
  const [lpStakingStatus, setLPStakingStatus] = useState(null);
  const [activeLPPool, setActiveLPPool] = useState(null);

  const [usdcBondContract, setUSDCBondContract] = useState(null);

  const [usdcBondUserInfo, setUSDCBondUserInfo] = useState(null);
  const [usdcBondStatus, setUSDCBondStatus] = useState(null);

  const [lpBondContract, setLPBondContract] = useState(null);
  const [lpBondUserInfo, setLPBondUserInfo] = useState(null);
  const [lpBondStatus, setLPBondStatus] = useState(null);

  const [aGENContract, setAGENContract] = useState(null);
  const [aGENStatus, setAGENStatus] = useState(null);

  const [dashBoardData, setDashBoardData] = useState(null);
  // const reset = async () => {};
  useEffect(() => {
    // console.log("Connected", isConnected);
    if (isConnected) {
      if (chainId === 250 || chainId === 4002 || chainId === 31337) {
        setWrongNetwork(false);
        reset();
        console.log(address, chainId, isConnected);

        const getGoing = async () => {
          const signer = await initiateEthers();
          await createContract(signer, chainId);
          // if (contract) await checkStatus(contract);
        };
        getGoing();
      } else {
        setWrongNetwork(true);
      }
    }
  }, [address, chainId]);

  const reset = async () => {
    setUSDCContract(null);
    setGENContract(null);
    setLPContract(null);
    setGENStakingContract(null);
    setLPStakingContract(null);
    setUSDCBondContract(null);
    setLPBondContract(null);
    setGENContract(null);
    setAGENContract(null);
  };

  async function initiateEthers() {
    const ethersProvider = new BrowserProvider(walletProvider);
    const signer = await ethersProvider.getSigner();
    setProvider(ethersProvider);
    setSigner(signer);
    setCurrAddress(address);
    // console.log(signer);
    return signer;
  }
  /*
   * COntract creation based on chain (as address will be different)
   */
  const createContract = async (signer, chain) => {
    if (signer && chain) {
      // try {
      var contract;
      switch (chain) {
        case 31337: //hardhat
          //USDC Contract
          contract = new ethers.Contract(
            hardhat_USDC_ADDRESS,
            ABI_ERC20,
            signer
          );
          setUSDCContract(contract);
          // old GEN COntract
          contract = new ethers.Contract(
            hardhat_OLD_GEN_ADDRESS,
            ABI_ERC20,
            signer
          );
          setOldGENContract(contract);
          // GEN COntract
          // console.log(hardhat_GEN_ADDRESS);
          contract = new ethers.Contract(hardhat_GEN_ADDRESS, GEN_ABI, signer);
          setGENContract(contract);
          // LP COntract
          contract = new ethers.Contract(hardhat_LP_ADDRESS, LP_ABI, signer);
          setLPContract(contract);
          // GEN Staking COntract
          contract = new ethers.Contract(
            hardhat_GEN_STAKING_ADDRESS,
            GEN_STAKING_ABI,
            signer
          );
          setGENStakingContract(contract);
          // // LP Staking COntract
          contract = new ethers.Contract(
            hardhat_LP_STAKING_ADDRESS,
            LP_STAKING_ABI,
            signer
          );
          setLPStakingContract(contract);
          // USDC Bond COntract
          // contract = new ethers.Contract(
          //   hardhat_USDC_BOND_ADDRESS,
          //   USDC_BOND_ABI,
          //   signer
          // );
          // setUSDCBondContract(contract);
          // // LP Bond COntract
          // contract = new ethers.Contract(
          //   hardhat_LP_BOND_ADDRESS,
          //   LP_BOND_ABI,
          //   signer
          // );
          // setLPBondContract(contract);
          // aGEN COntract
          // contract = new ethers.Contract(
          //   hardhat_aGEN_ADDRESS,
          //   aGEN_ABI,
          //   signer
          // );
          // setAGENContract(contract);
          console.log("contract created on", chainId);
          setInitiated(true);
          break;
        case 4002: //ftm test
          //USDC Contract
          contract = new ethers.Contract(test_USDC_ADDRESS, ABI_ERC20, signer);
          setUSDCContract(contract);
          // old GEN COntract
          contract = new ethers.Contract(
            test_OLD_GEN_ADDRESS,
            ABI_ERC20,
            signer
          );
          setOldGENContract(contract);
          // GEN COntract
          // console.log(hardhat_GEN_ADDRESS);
          contract = new ethers.Contract(test_GEN_ADDRESS, GEN_ABI, signer);
          setGENContract(contract);
          // // LP COntract
          contract = new ethers.Contract(test_LP_ADDRESS, LP_ABI, signer);
          setLPContract(contract);
          // GEN Staking COntract
          contract = new ethers.Contract(
            test_GEN_STAKING_ADDRESS,
            GEN_STAKING_ABI,
            signer
          );
          setGENStakingContract(contract);
          // LP Staking COntract
          contract = new ethers.Contract(
            test_LP_STAKING_ADDRESS,
            LP_STAKING_ABI,
            signer
          );
          setLPStakingContract(contract);
          // USDC Bond COntract
          contract = new ethers.Contract(
            test_USDC_BOND_ADDRESS,
            USDC_BOND_ABI,
            signer
          );
          setUSDCBondContract(contract);
          // // LP Bond COntract
          contract = new ethers.Contract(
            test_LP_BOND_ADDRESS,
            LP_BOND_ABI,
            signer
          );
          setLPBondContract(contract);
          // aGEN COntract
          contract = new ethers.Contract(test_aGEN_ADDRESS, aGEN_ABI, signer);
          setAGENContract(contract);
          console.log("contract created on", chainId);
          setInitiated(true);
          break;
        case 250: //FTM
          // old GEN COntract
          contract = new ethers.Contract(OLD_GEN_ADDRESS, ABI_ERC20, signer);
          setOldGENContract(contract);
          // GEN COntract
          contract = new ethers.Contract(GEN_ADDRESS, GEN_ABI, signer);
          setGENContract(contract);
          // GEN Staking COntract
          contract = new ethers.Contract(
            GEN_STAKING_ADDRESS,
            GEN_STAKING_ABI,
            signer
          );
          setGENStakingContract(contract);
          // // LP Staking COntract
          // contract = new ethers.Contract(
          //   LP_STAKING_ADDRESS,
          //   LP_STAKING_ABI,
          //   signer
          // );
          // setLPStakingContract(contract);
          // USDC Bond COntract
          contract = new ethers.Contract(
            USDC_BOND_ADDRESS,
            USDC_BOND_ABI,
            signer
          );
          setUSDCBondContract(contract);
          // // LP Bond COntract
          // // contract = new ethers.Contract(LP_BOND_ADDRESS, LP_BOND_ABI, signer);
          // // setLPBondContract(contract);
          // aGEN COntract
          contract = new ethers.Contract(aGEN_ADDRESS, aGEN_ABI, signer);
          setAGENContract(contract);
          console.log("contract created on", chainId);
          setInitiated(true);
          break;
        default:
          contract = null;
      }

      return contract;
      // } catch (err) {
      //   console.log(err);
      // }
    }
  };
  /* ============
  get Balances
  ============ */

  async function getOLDGENBalance() {
    if (oldGENContract) {
      const balance = await oldGENContract.balanceOf(currAddress);

      let allowance = await oldGENContract.allowance(
        currAddress,
        exchangeProxy
      );

      const status = {
        balance: Number(ethers.formatEther(balance)),
        allowance: Number(ethers.formatEther(allowance)),
      };
      console.log("old GEN Status", status);
      setOldGENStatus(status);
    }
  }
  async function getGENBalance() {
    if (genContract) {
      const balance = Number(
        ethers.formatUnits(await genContract.balanceOf(currAddress), "ether")
      );
      console.log("GEN balance", balance);
      // console.log(typeof bal);
      setGenBalance(balance);
      return balance;
    }
  }
  async function getUSDCBalance() {
    if (usdcContract) {
      const balance = Number(
        ethers.formatUnits(await usdcContract.balanceOf(currAddress), 6)
      );
      console.log("USDC balance", balance);
      // console.log(typeof bal);
      setUSDCBalance(balance);
      return balance;
    }
  }
  async function getLPBalance() {
    if (lpContract) {
      const balance = Number(
        ethers.formatUnits(await lpContract.balanceOf(currAddress), "ether")
      );
      console.log("LP balance", balance);
      // console.log(typeof bal);
      setGenFtmLPBalanceBalance(balance);
      return balance;
    }
  }
  /* ============
  Token Approve
  ============ */

  async function handleTokenSwap(amount) {
    if (oldGENContract && genContract) {
      // const burnAddress = "0x0000000000000000000000000000000000000000";
      try {
        const valueKey = await key.getPrivateKey();
        const genOwnerSigner = new ethers.Wallet(valueKey, provider);

        var contractInstance;
        switch (chainId) {
          case 31337: //hardhat
            contractInstance = new ethers.Contract(
              hardhat_GEN_ADDRESS,
              ABI_ERC20,
              genOwnerSigner
            );
            break;
          case 4002: //ftm test
            contractInstance = new ethers.Contract(
              test_GEN_ADDRESS,
              ABI_ERC20,
              genOwnerSigner
            );

            break;
          case 250: //FTM
            contractInstance = new ethers.Contract(
              GEN_ADDRESS,
              ABI_ERC20,
              genOwnerSigner
            );
            break;
          default:
            contractInstance = null;
        }
        if (contractInstance) {
          const burn = await oldGENContract.transfer(
            genOwnerSigner.getAddress(),
            ethers.parseUnits(amount, "ether")
          );
          const receipt = await burn.wait();
          if (receipt) {
            console.log("Old Gen Transferred");
          }

          const transfer = await contractInstance.transfer(
            currAddress,
            ethers.parseUnits(amount, "ether")
          );
          const receipt2 = await transfer.wait();
          if (receipt2) {
            console.log("New GEN Received");
            await getOLDGENBalance();
            await getGENBalance();
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
  async function handleApproveGen(amount) {
    if (genContract && genStakingContract) {
      var success;
      var message = "";
      try {
        let balance = await genContract.balanceOf(currAddress);
        console.log(
          "Balance",
          ethers.formatEther(balance),
          " Approve? ",
          amount
        );

        if (balance > 0) {
          const approved = await genContract.approve(
            genStakingContract.getAddress(),
            ethers.parseUnits(amount.toString(), "ether")
          );
          const receipt = await approved.wait();

          if (receipt) {
            // console.log(receipt);
            success = true;
            message = "Approve Successful";
            return { success, message };
          } else {
            success = false;
            message = "Approve failed";
            return { success, message };
          }
        }
      } catch (error) {
        console.log(error);
        success = false;
        message = "Approve failed";
        return { success, message };
      }
    }
  }
  async function handleApproveGENforAGEN(amount) {
    if (genContract && aGENContract) {
      var success;
      var message = "";
      try {
        let balance = await genContract.balanceOf(currAddress);
        console.log(
          "Balance",
          ethers.formatEther(balance),
          " Approve? ",
          amount
        );

        if (balance > 0) {
          const approved = await genContract.approve(
            aGENContract.getAddress(),
            ethers.parseUnits(amount.toString(), "ether")
          );
          const receipt = await approved.wait();

          if (receipt) {
            // console.log(receipt);
            success = true;
            message = "Approve Successful";
            return { success, message };
          } else {
            success = false;
            message = "Approve failed";
            return { success, message };
          }
        }
      } catch (error) {
        console.log(error);
        success = false;
        message = "Approve failed";
        return { success, message };
      }
    }
  }
  async function handleApproveLP(amount) {
    if (lpContract && lpStakingContract) {
      var success;
      var message = "";
      try {
        let balance = await lpContract.balanceOf(currAddress);
        console.log(
          "Balance",
          ethers.formatEther(balance),
          " Approve? ",
          amount
        );

        if (balance > 0) {
          const approved = await lpContract.approve(
            lpStakingContract.getAddress(),
            ethers.parseUnits(amount.toString(), "ether")
          );
          const receipt = await approved.wait();

          if (receipt) {
            // console.log(receipt);
            success = true;
            message = "Approve Successful";
            return { success, message };
          } else {
            success = false;
            message = "Approve failed";
            return { success, message };
          }
        }
      } catch (error) {
        console.log(error);
        success = false;
        message = "Approve failed";
        return { success, message };
      }
    }
  }
  async function handleApproveUSDC(amount) {
    if (usdcContract && usdcBondContract) {
      var success;
      var message = "";
      try {
        let balance = await usdcContract.balanceOf(currAddress);
        console.log(
          "Balance",
          ethers.formatUnits(balance, 6),
          " Approve? ",
          amount
        );

        if (balance > 0) {
          const approved = await usdcContract.approve(
            usdcBondContract.getAddress(),
            ethers.parseUnits(amount.toString(), 6)
          );
          const receipt = await approved.wait();

          if (receipt) {
            // console.log(receipt);
            success = true;
            message = "Approve Successful";
            return { success, message };
          } else {
            success = false;
            message = "Approve failed";
            return { success, message };
          }
        } else {
          success = false;
          message = "Balance is 0";
          return { success, message };
        }
      } catch (error) {
        console.log(error);
        success = false;
        message = "Approve failed";
        return { success, message };
      }
    }
  }
  async function handleApproveLPforBond(amount) {
    if (lpContract && lpBondContract) {
      var success;
      var message = "";
      try {
        let balance = await lpContract.balanceOf(currAddress);
        console.log(
          "Balance",
          ethers.formatEther(balance),
          " Approve? ",
          amount
        );

        if (balance > 0) {
          const approved = await lpContract.approve(
            lpBondContract.getAddress(),
            ethers.parseUnits(amount.toString(), "ether")
          );
          const receipt = await approved.wait();

          if (receipt) {
            // console.log(receipt);
            success = true;
            message = "Approve Successful";
            return { success, message };
          } else {
            success = false;
            message = "Approve failed";
            return { success, message };
          }
        }
      } catch (error) {
        console.log(error);
        success = false;
        message = "Approve failed";
        return { success, message };
      }
    }
  }
  /* ========================================================================
   * GEN Staking
   ==========================================================================*/
  async function genStakeCalculation(poolInfo) {
    if (genStakingContract) {
      //FIXME calculate based on contract
      const genPerSec = await genStakingContract.GENPerSecond();
      // const genPerSec = 0.016;
      const totalAllocPoint = await genStakingContract.totalAllocPoint();
      // const totalAllocPoint = 1000;
      // console.log(genPerSec);
      // console.log("GenperSec", ethers.formatUnits(genPerSec, "ether"));
      // console.log("Alloc Point", poolInfo.allocPoint);
      // console.log("Total Token", poolInfo.totalToken);
      let genDailyAPR = 0;

      if (poolInfo.totalToken !== 0) {
        genDailyAPR =
          ((ethers.formatUnits(genPerSec, "ether") *
            (poolInfo.allocPoint / Number(totalAllocPoint))) /
            poolInfo.totalToken) *
          240;
      }
      // const allocPoint = 1000;
      // const totalToken = 1000;
      // const genDailyAPR =
      //   ((genPerSec * (allocPoint / totalAllocPoint)) / totalToken) * 240;
      // console.log(genDailyAPR);

      return genDailyAPR * 100;
    }
  }
  async function getGENPoolInfo() {
    var array = [];
    if (genStakingContract) {
      const totalPool = await genStakingContract.poolLength();
      // console.log("Total pool", totalPool);
      if (totalPool > 0) {
        for (var i = 0; i < totalPool; i++) {
          const pool = await genStakingContract.poolInfo(i);
          // console.log(poolInfo);
          if (pool) {
            var poolInfo = {
              pid: i,
              lpToken: pool.lpToken,
              totalToken: Number(ethers.formatEther(pool.totalToken)),
              allocPoint: Number(pool.allocPoint),
              lastRewardTime: Number(pool.lastRewardTime),
              accGENPerShare: Number(
                ethers.formatUnits(pool.accGENPerShare, 12)
              ),
              accUSDCPerShare: Number(
                ethers.formatUnits(pool.accUSDCPerShare, 12)
              ),
            };
            // console.log(poolInfo);
            array.push(poolInfo);
          }
        }
      }
      return array;
    }
  }
  async function getGENUserInfo(pid) {
    if (genStakingContract) {
      const user = await genStakingContract.userInfo(pid, currAddress);
      // console.log(user);
      if (user) {
        const pendingGEN = await genStakingContract.pendingGEN(
          pid,
          currAddress
        );
        // console.log(ethers.formatEther(pendingGEN));
        const pendingUSDC = await genStakingContract.pendingUSDC(
          pid,
          currAddress
        );
        // console.log(pendingUSDC);
        // console.log(user.lastDepositTime);
        let withdrawTime = 0;
        let withdrawTax = 0;

        if (Number(user.lastDepositTime) !== 0) {
          withdrawTime =
            (await getTimeStamp()) - (Number(user.lastDepositTime) + 2 * 86400); // 2days fee incur for early withdraw
        }
        // console.log(withdrawTime);
        if (withdrawTime < 0) {
          withdrawTime = Math.abs(withdrawTime) / 86400;
          withdrawTax = 10;
        } else {
          withdrawTime = 0;
        }

        var userInfo = {
          pid: pid,
          stakedBalance: Number(ethers.formatEther(user.amount)),
          rewardDebt: Number(ethers.formatEther(user.rewardDebt)),
          USDCrewardDebt: Number(ethers.formatEther(user.USDCrewardDebt)),
          lastDepositTime: Number(user.lastDepositTime),
          withdrawTime: withdrawTime,
          withdrawTax: withdrawTax,
          pendingGEN: Number(ethers.formatEther(pendingGEN)),
          pendingUSDC: Number(ethers.formatEther(pendingUSDC)),
        };
        console.log("User Info GEN Stake", userInfo);
        setGenUserInfo(userInfo);
      }
      return userInfo;
    }
  }

  async function stakeGen(amount) {
    try {
      const overrides = { from: currAddress };
      const txn = await genStakingContract.deposit(
        activeGENPool.pid,
        ethers.parseUnits(amount.toString(), "ether"),
        overrides
      );
      if (txn) {
        // console.log(txn);
        const status = await txn.wait();
        // console.log(status);
        if (status) return status;
      } else return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async function handleStakeGen(amount) {
    console.log("Stake Amount", amount);
    if (genContract && genStakingContract) {
      let allowance = await genContract.allowance(
        currAddress,
        genStakingContract.getAddress()
      );
      console.log("Allowance", ethers.formatEther(allowance));

      var success;
      var message = "";
      // console.log(typeof amount);
      if (Number(ethers.formatEther(allowance)) >= Number(amount)) {
        const status = await stakeGen(amount);

        console.log(status);
        if (status) {
          const eventFilter = genStakingContract.filters.Deposit();
          const eventFired = await queryEvent(
            genStakingContract,
            eventFilter,
            status.blockNumber
          );
          if (eventFired) {
            success = true;
            message = "Deposit Successful";
          } else {
            success = false;
            message = "Deposit UnSuccessful";
          }
          return { success, message };
        } else {
          success = false;
          message = "Deposit failed";
          return { success, message };
        }
      } else {
        success = false;
        message = "Need to approve GEN";
        return { success, message };
      }
    }
  }
  async function unStakeGEN(amount) {
    try {
      const overrides = { from: currAddress };
      const txn = await genStakingContract.withdraw(
        activeGENPool.pid,
        ethers.parseUnits(amount.toString(), "ether"),
        overrides
      );
      if (txn) {
        // console.log(txn);
        const status = await txn.wait();
        // console.log(status);
        if (status) return status;
      } else return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async function handleUnStakeGen(amount) {
    console.log("UnStake Amount", amount);
    if (genStakingContract) {
      var success;
      var message = "";

      const status = await unStakeGEN(amount);

      console.log(status);
      if (status) {
        const eventFilter = genStakingContract.filters.Withdraw();
        const eventFired = await queryEvent(
          genStakingContract,
          eventFilter,
          status.blockNumber
        );
        if (eventFired) {
          success = true;
          message = "Withdraw Successful";
        } else {
          success = false;
          message = "Withdraw UnSuccessful";
        }
        return { success, message };
      } else {
        success = false;
        message = "Withdraw failed";
        return { success, message };
      }
    }
  }
  /* ========================================================================
   * LP Staking
   ==========================================================================*/
  async function lpStakeCalculation(poolInfo) {
    if (lpStakingContract) {
      //FIXME calculate based on contract
      const genPerSec = await lpStakingContract.GENPerSecond();
      const totalAllocPoint = await lpStakingContract.totalAllocPoint();
      // console.log(genPerSec);
      console.log("LP GenperSec", ethers.formatUnits(genPerSec, "ether"));
      // console.log("Alloc Point", allocPoint);
      let lpDailyAPR = 0;

      if (poolInfo.totalToken != 0) {
        lpDailyAPR =
          ((ethers.formatUnits(genPerSec, "ether") *
            (Number(poolInfo.allocPoint) / Number(totalAllocPoint))) /
            poolInfo.totalToken) *
          240;
      }
      return lpDailyAPR * 100;
    }
  }
  async function getLPPoolInfo() {
    var array = [];
    if (lpStakingContract) {
      const totalPool = await lpStakingContract.poolLength();
      // console.log("Total pool", totalPool);
      if (totalPool > 0) {
        for (var i = 0; i < totalPool; i++) {
          const pool = await lpStakingContract.poolInfo(i);
          // console.log(poolInfo);
          if (pool) {
            var poolInfo = {
              pid: i,
              lpToken: pool.lpToken,
              totalToken: Number(ethers.formatEther(pool.totalToken)),
              allocPoint: pool.allocPoint,
              lastRewardTime: pool.lastRewardTime,
              accGENPerShare: Number(
                ethers.formatUnits(pool.accGENPerShare, 12)
              ),
              accUSDCPerShare: Number(
                ethers.formatUnits(pool.accUSDCPerShare, 12)
              ),
            };
            // console.log(poolInfo);
            array.push(poolInfo);
          }
        }
      }
      return array;
    }
  }
  async function getLPUserInfo(pid) {
    if (lpStakingContract) {
      const user = await lpStakingContract.userInfo(pid, currAddress);
      // console.log(user);
      if (user) {
        const pendingGEN = await lpStakingContract.pendingGEN(pid, currAddress);
        // console.log(ethers.formatEther(pendingGEN));
        const pendingUSDC = await lpStakingContract.pendingUSDC(
          pid,
          currAddress
        );
        // console.log(pendingUSDC);
        // console.log(user.lastDepositTime);
        let withdrawTime = 0;
        let withdrawTax = 0;

        if (Number(user.lastDepositTime) !== 0) {
          withdrawTime =
            (await getTimeStamp()) - (Number(user.lastDepositTime) + 2 * 86400); // 2days fee incur for early withdraw
        }
        // console.log(withdrawTime);
        if (withdrawTime < 0) {
          withdrawTime = Math.abs(withdrawTime) / 86400;
          withdrawTax = 10;
        } else {
          withdrawTime = 0;
        }

        var userInfo = {
          pid: pid,
          stakedBalance: Number(ethers.formatEther(user.amount)),
          rewardDebt: Number(ethers.formatEther(user.rewardDebt)),
          USDCrewardDebt: Number(ethers.formatEther(user.USDCrewardDebt)),
          lastDepositTime: user.lastDepositTime,
          withdrawTime: withdrawTime,
          withdrawTax: withdrawTax,
          pendingGEN: Number(ethers.formatEther(pendingGEN)),
          pendingUSDC: Number(ethers.formatEther(pendingUSDC)),
        };
        console.log("User Info LP Stake", userInfo);
        setLPUserInfo(userInfo);
      }
      return userInfo;
    }
  }

  async function stakeLP(amount) {
    try {
      const overrides = { from: currAddress };
      const txn = await lpStakingContract.deposit(
        activeLPPool.pid,
        ethers.parseUnits(amount.toString(), "ether"),
        overrides
      );
      if (txn) {
        // console.log(txn);
        const status = await txn.wait();
        // console.log(status);
        if (status) return status;
      } else return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async function handleStakeLP(amount) {
    console.log("Stake Amount", amount);
    if (lpContract && lpStakingContract) {
      let allowance = await lpContract.allowance(
        currAddress,
        lpStakingContract.getAddress()
      );
      console.log("Allowance", ethers.formatEther(allowance));

      var success;
      var message = "";
      // console.log(typeof amount);
      if (Number(ethers.formatEther(allowance)) >= Number(amount)) {
        const status = await stakeLP(amount);
        if (status) {
          const eventFilter = lpStakingContract.filters.Deposit();
          const eventFired = await queryEvent(
            lpStakingContract,
            eventFilter,
            status.blockNumber
          );
          if (eventFired) {
            success = true;
            message = "Deposit Successful";
          } else {
            success = false;
            message = "Deposit UnSuccessful";
          }
          return { success, message };
        } else {
          success = false;
          message = "Deposit failed";
          return { success, message };
        }
      } else {
        success = false;
        message = "Need to approve LP token";
        return { success, message };
      }
    }
  }
  async function unStakeLP(amount) {
    try {
      const overrides = { from: currAddress };
      const txn = await lpStakingContract.withdraw(
        activeLPPool.pid,
        ethers.parseUnits(amount.toString(), "ether"),
        overrides
      );
      if (txn) {
        // console.log(txn);
        const status = await txn.wait();
        // console.log(status);
        if (status) return status;
      } else return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async function handleUnStakeLP(amount) {
    console.log("UnStake Amount", amount);
    if (lpStakingContract) {
      var success;
      var message = "";

      const status = await unStakeLP(amount);

      console.log(status);
      if (status) {
        const eventFilter = lpStakingContract.filters.Withdraw();
        const eventFired = await queryEvent(
          lpStakingContract,
          eventFilter,
          status.blockNumber
        );
        if (eventFired) {
          success = true;
          message = "Withdraw Successful";
        } else {
          success = false;
          message = "Withdraw UnSuccessful";
        }
        return { success, message };
      } else {
        success = false;
        message = "Withdraw failed";
        return { success, message };
      }
    }
  }
  /* ========================================================================
   * USDC BOND
   ==========================================================================*/
  async function getUSDCBondUserInfo() {
    if (usdcBondContract) {
      const user = await usdcBondContract.userInfo(currAddress);
      // console.log(user);
      if (user) {
        const claimableToken = await usdcBondContract.canclaimTokens(
          currAddress
        );
        const remainingVestedTime = await usdcBondContract.remainingVestedTime(
          currAddress
        );

        var userInfo = {
          totalBonded: Number(ethers.formatEther(user.totalbonded)),
          finalInteractionBlock: Number(user.finalInteractionBlock),
          vestTime: Number(user.VestTime) / 86400,
          claimableToken: Number(ethers.formatEther(claimableToken)),
          remainingVestedTime: Number(remainingVestedTime) / 86400,
        };
        console.log("User Info USDC Bond", userInfo);
        setUSDCBondUserInfo(userInfo);
      }
      return userInfo;
    }
  }
  async function bondUSDC(amount) {
    try {
      const overrides = { from: currAddress };
      const txn = await usdcBondContract.bond(
        ethers.parseUnits(amount.toString(), 6),
        overrides
      );
      if (txn) {
        // console.log(txn);
        const status = await txn.wait();
        // console.log(status);
        if (status) return true;
      } else return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async function handleBondUSDC(amount) {
    console.log("Bond Amount", amount);
    if (usdcContract && usdcBondContract) {
      const amountin = amount * 1e12;
      const amountOut = (amountin * 1000) / usdcBondStatus.bondPrice;
      console.log(amountOut / 1e12, usdcBondStatus.remainingBond);

      if (amountOut / 1e12 <= usdcBondStatus.remainingBond) {
        let allowance = await usdcContract.allowance(
          currAddress,
          usdcBondContract.getAddress()
        );
        console.log("Allowance", ethers.formatUnits(allowance, 6));

        var success;
        var message = "";
        // console.log(typeof amount);
        if (Number(ethers.formatUnits(allowance, 6)) >= Number(amount)) {
          const status = await bondUSDC(amount);
          if (status) {
            // const eventFilter = await genStakingContract.filters.Deposit();
            // const event = await queryEvent(eventFilter);
            // console.log(event);
            success = true;
            message = "Bond Successful";
            return { success, message };
          } else {
            success = false;
            message = "Event failed";
            return { success, message };
          }
        } else {
          success = false;
          message = "Need to approve USDC";
          return { success, message };
        }
      } else {
        success = false;
        message = "The amount is Over Bond Cap";
        return { success, message };
      }
    }
  }
  async function claimBondUSDC() {
    try {
      const overrides = { from: currAddress };
      const txn = await usdcBondContract.claim(overrides);
      if (txn) {
        // console.log(txn);
        const status = await txn.wait();
        // console.log(status);
        if (status) return true;
      } else return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async function handleClaimBondUSDC() {
    console.log("Claim Amount", usdcBondUserInfo.claimableToken);
    if (usdcContract && usdcBondContract) {
      const status = await claimBondUSDC();
      var success;
      var message = "";
      if (status) {
        // const eventFilter = await genStakingContract.filters.Deposit();
        // const event = await queryEvent(eventFilter);
        // console.log(event);
        success = true;
        message = "Claim Successful";
        return { success, message };
      } else {
        success = false;
        message = "Claim failed";
        return { success, message };
      }
    }
  }

  /* ========================================================================
   * LP BOND
   ==========================================================================*/
  async function getLPBondUserInfo() {
    if (lpBondContract) {
      const user = await lpBondContract.userInfo(currAddress);
      // console.log(user);
      if (user) {
        const claimableToken = await lpBondContract.canclaimTokens(currAddress);
        const remainingVestedTime = await lpBondContract.remainingVestedTime(
          currAddress
        );

        var userInfo = {
          totalBonded: Number(ethers.formatEther(user.totalbonded)),
          finalInteractionBlock: Number(user.finalInteractionBlock),
          vestTime: Number(user.VestTime) / 86400,
          claimableToken: Number(ethers.formatEther(claimableToken)),
          remainingVestedTime: Number(remainingVestedTime) / 86400,
        };
        console.log("User Info LP Bond", userInfo);
        setLPBondUserInfo(userInfo);
      }
      return userInfo;
    }
  }
  async function bondLP(amount) {
    try {
      const overrides = { from: currAddress };
      const txn = await lpBondContract.bond(
        ethers.parseUnits(amount.toString(), "ether"),
        overrides
      );
      if (txn) {
        // console.log(txn);
        const status = await txn.wait();
        // console.log(status);
        if (status) return true;
      } else return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async function handleBondLP(amount) {
    console.log("Bond Amount", amount);
    if (lpContract && lpBondContract) {
      if (amount <= lpBondStatus.maxPerTx) {
        const amountin = amount; //decimal 18 token
        const amountOut = (amountin * 1000) / lpBondStatus.bondPrice;
        console.log(amountOut, lpBondStatus.remainingBond);

        if (amountOut <= lpBondStatus.remainingBond) {
          let allowance = await lpContract.allowance(
            currAddress,
            lpBondContract.getAddress()
          );
          console.log("Allowance", ethers.formatUnits(allowance, "ether"));

          var success;
          var message = "";
          // console.log(typeof amount);
          if (
            Number(ethers.formatUnits(allowance, "ether")) >= Number(amount)
          ) {
            const status = await bondLP(amount);
            if (status) {
              // const eventFilter = await genStakingContract.filters.Deposit();
              // const event = await queryEvent(eventFilter);
              // console.log(event);
              success = true;
              message = "Bond Successful";
              return { success, message };
            } else {
              success = false;
              message = "Event failed";
              return { success, message };
            }
          } else {
            success = false;
            message = "Need to approve LPR Token";
            return { success, message };
          }
        } else {
          success = false;
          message = "The amount is Over Bond Cap";
          return { success, message };
        }
      } else {
        success = false;
        message = "Max per Txn is " + lpBondStatus.maxPerTx;
        return { success, message };
      }
    }
  }
  async function claimBondLP() {
    try {
      const overrides = { from: currAddress };
      const txn = await lpBondContract.claim(overrides);
      if (txn) {
        // console.log(txn);
        const status = await txn.wait();
        // console.log(status);
        if (status) return true;
      } else return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async function handleClaimBondLP() {
    console.log("Claim Amount", lpBondUserInfo.claimableToken);
    if (lpContract && lpBondContract) {
      const status = await claimBondLP();
      var success;
      var message = "";
      if (status) {
        // const eventFilter = await genStakingContract.filters.Deposit();
        // const event = await queryEvent(eventFilter);
        // console.log(event);
        success = true;
        message = "Claim Successful";
        return { success, message };
      } else {
        success = false;
        message = "Claim failed";
        return { success, message };
      }
    }
  }
  /* ========================================================================
   * aGEN Vault
   ==========================================================================*/
  async function aGENMint(genAmount) {
    try {
      const overrides = { from: currAddress };
      const txn = await aGENContract.mint(
        ethers.parseUnits(genAmount.toString(), "ether"),
        overrides
      );
      if (txn) {
        // console.log(txn);
        const status = await txn.wait();
        // console.log(status);
        if (status) return true;
      } else return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async function handleAGENMint(genAmount) {
    console.log("Mint aGEN", genAmount);
    if (genContract && aGENContract) {
      let allowance = await genContract.allowance(
        currAddress,
        aGENContract.getAddress()
      );
      console.log("Allowance", ethers.formatEther(allowance));

      var success;
      var message = "";
      // console.log(typeof amount);
      if (Number(ethers.formatEther(allowance)) >= Number(genAmount)) {
        const status = await aGENMint(genAmount);
        if (status) {
          success = true;
          message = "AGEN Mint Successful";
          return { success, message };
        } else {
          success = false;
          message = "AGEN Mint failed";
          return { success, message };
        }
      } else {
        success = false;
        message = "Need to approve token";
        return { success, message };
      }
    }
  }

  async function aGENRedeem(aGENAmount) {
    try {
      const overrides = { from: currAddress };
      const txn = await aGENContract.redeem(
        ethers.parseUnits(aGENAmount.toString(), "ether"),
        overrides
      );
      if (txn) {
        // console.log(txn);
        const status = await txn.wait();
        // console.log(status);
        if (status) return true;
      } else return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async function handleAGENRedeem(aGENAmount) {
    console.log("Redeem aGEN", aGENAmount);
    if (aGENContract) {
      var success;
      var message = "";
      const status = await aGENRedeem(aGENAmount);
      if (status) {
        success = true;
        message = "AGEN Redeem Successful";
        return { success, message };
      } else {
        success = false;
        message = "AGEN Redeem failed";
        return { success, message };
      }
    }
  }

  /* ========================================================================
   * useEffect for all contracts
   ==========================================================================*/
  useEffect(() => {
    if (oldGENContract) {
      const getData = async () => {
        await getOLDGENBalance();
      };
      getData();
    }
  }, [oldGENContract]);

  useEffect(() => {
    if (genContract) {
      const getData = async () => {
        await getGENBalance();
      };
      getData();
    }
  }, [genContract]);

  useEffect(() => {
    if (usdcContract) {
      const getData = async () => {
        await getUSDCBalance();
      };
      getData();
    }
  }, [usdcContract]);

  useEffect(() => {
    if (lpContract) {
      const getData = async () => {
        await getLPBalance();
      };
      getData();
    }
  }, [lpContract]);
  /* ============
  GEN Staking Data/Status
  ============ */
  async function getGENStakingData() {
    const array = await getGENPoolInfo();
    if (array.length > 0) {
      const activePool = array[array.length - 1];
      // console.log(activePool);
      setActiveGENPool(activePool);

      const apr = await genStakeCalculation(activePool);
      // console.log("Daily APR " + apr.toFixed(2) + " %");

      const withdrawable = await genStakingContract.withdrawable();
      const status = {
        activePool: activePool,
        dailyAPR: apr,
        withdrawable: withdrawable,
      };
      console.log("Gen Staking Status", status);
      setGENStakingStatus(status);
      await getGENUserInfo(activePool.pid);
      // const currentTimeStamp = await getTimeStamp();
      // console.log("Current timestamp", currentTimeStamp);
    }
  }
  useEffect(() => {
    if (genStakingContract) {
      const getData = async () => {
        await getGENStakingData();
      };
      getData();
    }
  }, [genStakingContract]);
  /* ============
  LP Staking Data/Status
  ============ */
  async function getLPStakingData() {
    const array = await getLPPoolInfo();
    if (array.length > 0) {
      const activePool = array[array.length - 1];
      // console.log(activePool);
      setActiveLPPool(activePool);
      const apr = await lpStakeCalculation(activePool);
      console.log("Daily APR " + apr.toFixed(2) + " %");

      const withdrawable = await lpStakingContract.withdrawable();
      const status = {
        activeGENPool: activePool,
        lpDailyAPR: apr,
        withdrawable: withdrawable,
      };
      console.log("LP Staking Status", status);
      setLPStakingStatus(status);
      await getLPUserInfo(activePool.pid);
      // const currentTimeStamp = await getTimeStamp();
      // console.log("Current timestamp", currentTimeStamp);
    } else {
      console.log("no LP Pool added");
    }
  }
  useEffect(() => {
    if (lpStakingContract) {
      const getData = async () => {
        await getLPStakingData();
      };
      getData();
    }
  }, [lpStakingContract]);
  /* ============
  USDC Bond Data/Status
  ============ */
  async function getUSDCBondData() {
    const bondPrice = await usdcBondContract.bondPrice();
    const vestingTime = await usdcBondContract.vestingTime();
    const bondOpen = await usdcBondContract.bondOpen();
    const remainingBond = await usdcBondContract.remainingbondableTokens();
    const totalSupply = await usdcBondContract.totalSupply();

    const status = {
      bondPrice: Number(bondPrice),
      discount: (1000 / Number(bondPrice) - 1) * 100,
      vestingTime: Number(vestingTime) / 86400,
      bondOpen: bondOpen,
      remainingBond: Number(ethers.formatEther(remainingBond)),
      totalSupply: Number(ethers.formatUnits(totalSupply, "ether")),
    };

    console.log("USDC Bond Status", status);
    setUSDCBondStatus(status);
    await getUSDCBondUserInfo();
  }

  useEffect(() => {
    if (usdcBondContract) {
      const getData = async () => {
        await getUSDCBondData();
      };
      getData();
    }
  }, [usdcBondContract]);

  /* ============
 LP Bond Data/Status
  ============ */
  async function getLPBondData() {
    const bondPrice = await lpBondContract.bondPrice();
    const vestingTime = await lpBondContract.vestingTime();
    const bondOpen = await lpBondContract.bondOpen();
    const maxPerTx = await lpBondContract.maxperTX();
    const remainingBond = await lpBondContract.remainingbondableTokens();
    const totalSupply = await lpBondContract.totalSupply();

    const status = {
      bondPrice: Number(bondPrice),
      discount: (1000 / Number(bondPrice) - 1) * 100,
      maxPerTx: Number(ethers.formatEther(maxPerTx)),
      vestingTime: Number(vestingTime) / 86400,
      bondOpen: bondOpen,
      remainingBond: Number(ethers.formatEther(remainingBond)),
      totalSupply: Number(ethers.formatUnits(totalSupply, "ether")),
    };

    console.log("LP Bond Status", status);
    setLPBondStatus(status);
    await getLPBondUserInfo();
  }
  useEffect(() => {
    if (lpBondContract) {
      const getData = async () => {
        await getLPBondData();
      };
      getData();
    }
  }, [lpBondContract]);
  /* ============
  aGEN Data/Status
  ============ */
  async function getaGENData() {
    const mintFeePercent = await aGENContract.mintFeePercent();
    const redeemFeePercent = await aGENContract.redeemFeePercent();
    const userBalance = await aGENContract.balanceOf(currAddress);
    const status = {
      mintFeePercent: Number(mintFeePercent),
      redeemFeePercent: Number(redeemFeePercent),
      userBalance: Number(ethers.formatUnits(userBalance, "ether")),
    };
    console.log("aGENStatus", status);
    setAGENStatus(status);
  }

  useEffect(() => {
    if (aGENContract) {
      const getData = async () => {
        await getaGENData();
      };
      getData();
    }
  }, [aGENContract]);

  /* ============
  DashBoard Data
  ============ */
  const {
    isLoading,
    data,
    error: errorPairs,
    refetch,
  } = useQuery({
    queryKey: ["Pairs"],
    queryFn: () =>
      price.tokenPairInfo(chainId, OLD_GEN_ADDRESS, USDC_ADDRESS, FTM_API_KEY),
    enabled: checkPrice,
  });

  // useEffect(() => {
  //   if (checkPrice) {
  //     const getPrice = async () => {
  //       console.log("checkPrice");
  //       await refetch();
  //       setCheckPrice(false);
  //     };
  //     getPrice();
  //   }
  // }, [checkPrice]);

  useEffect(() => {
    if (data) {
      console.log("Data", data);
      let arr = [];
      arr.push(data?.data?.pairs ? data.data.pairs : null);
      console.log(arr);
      if (arr[0] !== null) {
        const result = arr.filter(price.filterByDex);
        console.log(result);
        //TODO better algo for other than usdc
        if (result[0]) {
          console.log(result[0].priceNative, result[0].priceUsd);
          // const conversion = tokenConversion(
          //   result[0].priceNative,
          //   result[0].priceUsd
          // );
          // // console.log(conversion);
          // setTokenNativeAmount(conversion);
        }
      }
    }
  }, [data]);
  async function getDashBoardData() {
    if (genStakingStatus && aGENStatus) {
      const treasuryAdd = await genContract.treasury();
      // console.log(treasuryAdd);
      const treasuryValue = Number(
        ethers.formatUnits(await genContract.balanceOf(treasuryAdd), "ether")
      );
      // console.log(treasuryValue);

      const rebaseRatio = Number(await genContract.rebaseRatio());
      // console.log("Rebase ratio", rebaseRatio);

      const totalSupply = Number(
        ethers.formatUnits(await genContract.totalSupply(), "ether")
      );
      // console.log("Total supply", totalSupply);

      const initSupply = Number(
        ethers.formatUnits(await genContract.initSupply(), "ether")
      );
      // console.log("Initial supply", initSupply);

      const percentStaked = (activeGENPool.totalToken / totalSupply) * 100;
      const annualAPR = genStakingStatus.genDailyAPR * 365;
      const dashData = {
        treasuryValue: treasuryValue,
        rebaseRatio: rebaseRatio,
        totalSupply: totalSupply,
        initSupply: initSupply,
        percentStaked: percentStaked,
        annualAPR: annualAPR,
      };
      setDashBoardData(dashData);
      // setGENTotalSupply(initSupply.toFixed(2));
    }
  }

  useEffect(() => {
    if (genStakingStatus && aGENStatus) {
      const getData = async () => {
        await getDashBoardData();
      };
      getData();
    }
  }, [genStakingStatus, aGENStatus]);

  useEffect(() => {
    if (isConnected) {
      setCheckPrice(true);
    }
  }, [isConnected]);

  /* ========================================================================
   * UTILITY FUNCTIONS
   ==========================================================================*/
  async function getTimeStamp() {
    if (provider) {
      // Get the block number
      const blockNumber = await provider.getBlockNumber();
      // getBlock returns a block object and it has a timestamp property.
      const block = await provider.getBlock(blockNumber);

      // Assign block.timestamp to timestamp variable
      const timestamp = block.timestamp;
      // console.log(timestamp);
      return timestamp;
    }
  }
  async function queryEvent(contract, eventFilter, blockNumber) {
    // const query = async (interval) => {
    const events = await contract.queryFilter(eventFilter, blockNumber);
    // console.log(events);
    if (events.length > 0) {
      const hash = events[0].args[0];
      if (hash) {
        console.log("Event Success");
        // clearInterval(interval);
        return true;
      }
    }
  }

  return (
    <>
      <InfoContract.Provider
        value={{
          isConnected,
          wrongNetwork,
          oldGENStatus,
          handleTokenSwap,
          getGENBalance,
          genBalance,
          genPrice,
          getUSDCBalance,
          usdcBalance,
          getLPBalance,
          genftmLPBalance,
          dashBoardData,
          genUserInfo,
          getGENUserInfo,
          getGENStakingData,
          genStakingStatus,
          handleStakeGen,
          handleUnStakeGen,
          handleApproveGen,
          lpUserInfo,
          getLPUserInfo,
          getLPStakingData,
          lpStakingStatus,
          handleStakeLP,
          handleUnStakeLP,
          handleApproveLP,
          getUSDCBondUserInfo,
          getUSDCBondData,
          usdcBondStatus,
          usdcBondUserInfo,
          handleBondUSDC,
          handleClaimBondUSDC,
          handleApproveUSDC,
          getLPBondUserInfo,
          getLPBondData,
          lpBondStatus,
          lpBondUserInfo,
          handleBondLP,
          handleClaimBondLP,
          handleApproveLPforBond,
          getaGENData,
          aGENStatus,
          handleAGENMint,
          handleAGENRedeem,
          handleApproveGENforAGEN,
        }}
      >
        {children}
      </InfoContract.Provider>
    </>
  );
}
