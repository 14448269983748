"use client";

import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "20b314c5e35f9d2fb7738813c0884331";

// 2. Set chains
const ftm_main = {
  chainId: 250, //0xfa
  name: "Fantom",
  currency: "Fantom Opera",
  explorerUrl: "https://ftmscan.com",
  rpcUrl: "https://rpc.ankr.com/fantom",
};

// 2. Set chains
const ftm_Test = {
  chainId: 4002, //0xfa
  name: "Fantom Test",
  currency: "FTMTEST",
  explorerUrl: "https://testnet.ftmscan.com/",
  rpcUrl: "https://rpc.testnet.fantom.network/",
};

const hardhat = {
  chainId: 31337, //0x7a69
  name: "Hardhat",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "http://127.0.0.1:8545/",
};

// 3. Create modal
const metadata = {
  name: "Generator",
  description: "My Website description",
  url: "https://mywebsite.com", // origin must match your domain & subdomain
  icons: ["https://avatars.mywebsite.com/"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [ftm_main, ftm_Test, hardhat],
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  themeMode: "light",
  themeVariables: {
    "--w3m-accent": "#0d3050",
    "--w3m-color-mix": "#0d3050",
    "--w3m-color-mix-strength": 40,
  },
});

export function Web3ModalProvider({ children }) {
  return children;
}
