import "../styles/base.css";
import "../styles/sections.css";

import React, { useState, useEffect } from "react";
import Swap from "./Swap";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { Button, InputGroup, Input, Label } from "reactstrap";
import equilizer from "../images/equilizer_400x400.png";
import {
  API_KEY,
  WFTM_ADDRESS,
  hardhat_OLD_GEN_ADDRESS,
  hardhat_GEN_ADDRESS,
  OLD_GEN_ADDRESS,
  GEN_ADDRESS,
  USDC_ADDRESS,
  test_GEN_ADDRESS,
  test_OLD_GEN_ADDRESS,
} from "../web3/config";
import { UseContractInfo } from "../web3/ContractContext";
const AFFILIATE_FEE = 0.01; // Percentage of the buyAmount that should be attributed to feeRecipient as affiliate fees
const FEE_RECIPIENT = "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266"; // The ETH address that should receive affiliate fees

export function SwapUI() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { oldGENStatus } = UseContractInfo();

  const [tradeDirection, setTradeDirection] = useState("convert");
  const [swapAmount, setSwapAmount] = useState("");
  const [debouncedSwapAmount, setDebouncedSwapAmount] = useState("");

  const [returnAmount, setReturnAmount] = useState("");

  const [sellToken, setSellToken] = useState({
    symbol: "GEN",
    address: test_OLD_GEN_ADDRESS,
    decimals: 18,
  });
  const [buyToken, setBuyToken] = useState({
    symbol: "GEN",
    address: test_GEN_ADDRESS,
    decimals: 18,
  });

  const disabled =
    oldGENStatus && oldGENStatus.balance
      ? swapAmount > oldGENStatus.balance
      : true;

  const setMaxAmount = async () => {
    if (oldGENStatus) setSwapAmount(oldGENStatus.balance);
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => setDebouncedSwapAmount(swapAmount), 500);
    return () => clearTimeout(timeOutId);
  }, [swapAmount]);

  return (
    <>
      <div className="sub-section">
        <h3 className="mb-0 text-center">Swap old GEN token to GEN?</h3>
        <h5 className="mb-0 text-center">1:1 ratio</h5>
        <form>
          <div className="rounded-md mb-3">
            <div className="group mt-4">
              {/* <img
              //   alt={sellToken}
              className="h-9 w-9 mr-2 rounded-md"
              //   src={POLYGON_TOKENS_BY_SYMBOL[sellToken].logoURI}
            /> */}
              {/* <div className="h-14 sm:w-full sm:mr-2"> */}
              <InputGroup className="input-group">
                <Input
                  readOnly
                  type="select"
                  value={sellToken.symbol}
                  name="sell-token-select"
                  id="sell-token-select"
                  className="input-number rounded-md"
                  style={{
                    marginRight: 0,
                    backgroundColor: "transparent",
                    width: 100,
                  }}
                  // onChange={handleSellTokenChange}
                >
                  <option
                    key={sellToken.address}
                    value={sellToken.symbol.toLowerCase()}
                  >
                    {sellToken.symbol}
                  </option>
                </Input>
                <Input
                  id="sell-amount"
                  min="0"
                  placeholder="0"
                  type="number"
                  value={swapAmount}
                  className="rounded-md bg-transparent"
                  style={{ borderLeft: "1px solid black" }}
                  onChange={(e) => {
                    setTradeDirection("sell");
                    setSwapAmount(e.target.value);
                  }}
                />
                <Button
                  color=""
                  id="max"
                  style={{ borderRadius: 0, color: "black" }}
                  disabled={!isConnected}
                  onClick={() => {
                    setMaxAmount();
                  }}
                >
                  Max
                </Button>
              </InputGroup>

              {/* </div> */}
            </div>
            {/* <div className="subgroup"> */}

            {/* </div> */}
          </div>
          <div className="sub-group">
            {" "}
            <p className="text-right">
              Balance: {oldGENStatus && oldGENStatus.balance.toFixed(2)}
            </p>
            <p className="text-right highlight">
              Amount received: {Number(swapAmount).toFixed(2)} {buyToken.symbol}
            </p>
          </div>

          <div className="group">
            {address && (
              <ConvertButton
                debouncedSwapAmount={debouncedSwapAmount}
                disabled={disabled}
              />
            )}
          </div>
        </form>
      </div>
    </>
  );
}
function ConvertButton({ debouncedSwapAmount, disabled }) {
  const { oldGENStatus, handleApproveOldGEN, handleTokenSwap } =
    UseContractInfo();

  return (
    <Button
      outline
      color=""
      disabled={disabled}
      onClick={() => {
        // if (oldGENStatus && oldGENStatus.allowance <= 0) handleApproveOldGEN();
        // else
        if (debouncedSwapAmount > 0) handleTokenSwap(debouncedSwapAmount);
      }}
      className="btn-outline"
    >
      {disabled ? (
        "Insufficient Balance"
      ) : (
        <>{debouncedSwapAmount > 0 ? "Swap" : "Select Amount"}</>
      )}
    </Button>
  );
}

export default SwapUI;
