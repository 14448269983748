import "../styles/base.css";
import "../styles/menu.css";
import Stake from "../components/stake.js";
import { Button, Alert } from "reactstrap";
import { UseContractInfo } from "../web3/ContractContext";
import { FaExternalLinkAlt } from "react-icons/fa";
import React, { useState, useEffect, useContext } from "react";
import { Spinner } from "reactstrap";

export function LPStaking() {
  const {
    isConnected,
    genBalance,
    getLPBalance,
    genftmLPBalance,
    lpUserInfo,
    getLPStakingData,
    lpStakingStatus,
    handleStakeLP,
    handleUnStakeLP,
    handleApproveLP,
  } = UseContractInfo();

  const [trxnSucess, setTrxnSucess] = useState(null);
  const [gettingReceipt, setGettingReceipt] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleLPStaking = async (amount) => {
    setTrxnSucess(null);
    setGettingReceipt(true);
    const { success, message } = await handleStakeLP(amount);
    console.log(success, message);
    setTrxnSucess(success);
    setErrorMessage(message);
    setGettingReceipt(false);
  };

  const handleLPUnStaking = async (amount) => {
    setTrxnSucess(null);
    setGettingReceipt(true);
    const { success, message } = await handleUnStakeLP(amount);
    console.log(success, message);
    setTrxnSucess(success);
    setErrorMessage(message);
    setGettingReceipt(false);
  };

  const handleLPApprove = async (amount) => {
    setTrxnSucess(null);
    setGettingReceipt(true);
    const { success, message } = await handleApproveLP(amount);
    console.log(success, message);
    setTrxnSucess(success);
    setErrorMessage(message);
    setGettingReceipt(false);
  };

  useEffect(() => {
    if (trxnSucess === true) {
      const getData = async () => {
        await getLPStakingData();
        await getLPBalance();
      };
      getData();
    }
  }, [trxnSucess]);

  return (
    <div className="section-earn-detail" name="401staking">
      <h3 className="heading align-self-center double-line">
        LP Staking Equity
      </h3>

      <div className="sub-section group justify-evenly">
        <div className="my-align-center">
          <h4 className="light">Daily APR</h4>
          <h5 className="highlight">
            {lpStakingStatus && lpStakingStatus.lpDailyAPR.toFixed(2)} %
          </h5>
        </div>
        <div className="my-align-center">
          <h4 className="light">APR</h4>
          <h5 className="highlight">
            {lpStakingStatus && (lpStakingStatus.lpDailyAPR * 365).toFixed(2)} %
          </h5>
        </div>
        <div className="my-align-center">
          <h4 className="light">Total Staked</h4>
          <h5 className="highlight">
            {lpStakingStatus && lpStakingStatus.activeGENPool.totalToken} GENFTM
            LP
          </h5>
          {/* <h5 className="highlight">($909,534.16) </h5> */}
        </div>
      </div>
      <div className="sub-section">
        <Stake
          stakingToken="LP"
          stakingStatus={lpStakingStatus}
          userStakeStatus={lpUserInfo}
          handleStake={handleLPStaking}
          handleUnStake={handleLPUnStaking}
          handleApprove={handleLPApprove}
        />
        {gettingReceipt && (
          <>
            <div className="my-align-center">
              <Spinner className="text-center" type="border" color="info">
                {" "}
              </Spinner>
            </div>
          </>
        )}
        {trxnSucess !== null && (
          <Alert className="text-center" color={trxnSucess ? "info" : "danger"}>
            {errorMessage}
          </Alert>
        )}
        <div className="group mt-3 mb-3">
          <Button
            outline
            color=""
            className="btn-outline"
            size="lg"
            style={{ width: "100%" }}
          >
            Get LP Token <FaExternalLinkAlt className="ml-3" />
          </Button>
        </div>
      </div>
      <div className="sub-section text-center">
        <p>Pending GEN Reward: {lpUserInfo && lpUserInfo.pendingGEN}</p>
        <p>Pending USDC Reward: {lpUserInfo && lpUserInfo.pendingUSDC}</p>
        {/* <p>Your daily earnings: 0.00 $</p> */}
        <p>
          Note: The "Approve" transaction is only needed when staking/unstaking
          for the first time; subsequent staking/unstaking only requires you to
          perform the "Stake" or "Unstake" transaction.
        </p>
      </div>
      <div className="sub-section">
        <div className="row justify-between light">
          <div className="text-left">
            <p>Your Balance</p>
            <p>Your Staked Balance</p>
            <p>GEN reward accrued</p>
            <p>Pending GEN Reward</p>
            <p>USDC reward accrued</p>
            <p>Pending USDC Reward</p>
            <p>Time until tax exempt</p>
            <p>Withdraw tax</p>
          </div>
          <div className="text-right">
            <p>{genftmLPBalance.toFixed(2)} GENFTM LP</p>
            <p>{lpUserInfo && lpUserInfo.stakedBalance.toFixed(2)} veGEN</p>
            <p>{lpUserInfo && lpUserInfo.rewardDebt.toFixed(2)} GEN</p>
            <p>{lpUserInfo && lpUserInfo.pendingGEN.toFixed(2)} GEN</p>
            <p>{lpUserInfo && lpUserInfo.USDCrewardDebt.toFixed(2)} USDC</p>
            <p>{lpUserInfo && lpUserInfo.pendingUSDC.toFixed(2)} USDC</p>
            <p>{lpUserInfo && lpUserInfo.withdrawTime.toFixed(2)} days</p>
            <p>{lpUserInfo && lpUserInfo.withdrawTax} %</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LPStaking;
