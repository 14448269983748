import "../styles/base.css";
import "../styles/menu.css";
import { Button, Alert } from "reactstrap";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Redeem } from "../components/redeem";
import { UseContractInfo } from "../web3/ContractContext";
import React, { useState, useEffect, useContext } from "react";
import { Spinner } from "reactstrap";

export function AGENRedeem() {
  const {
    getGENBalance,
    genBalance,
    getaGENData,
    aGENStatus,
    handleAGENMint,
    handleAGENRedeem,
    handleApproveGENforAGEN,
    handleApproveAGEN,
  } = UseContractInfo();

  const [trxnSucess, setTrxnSucess] = useState(null);
  const [gettingReceipt, setGettingReceipt] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleMint = async (amount) => {
    setTrxnSucess(null);
    setGettingReceipt(true);
    const { success, message } = await handleAGENMint(amount);
    console.log(success, message);
    setTrxnSucess(success);
    setErrorMessage(message);
    setGettingReceipt(false);
  };

  const handleRedeem = async (amount) => {
    setTrxnSucess(null);
    setGettingReceipt(true);
    const { success, message } = await handleAGENRedeem(amount);
    console.log(success, message);
    setTrxnSucess(success);
    setErrorMessage(message);
    setGettingReceipt(false);
  };

  const handleGENApprove = async (amount) => {
    setTrxnSucess(null);
    setGettingReceipt(true);
    const { success, message } = await handleApproveGENforAGEN(amount);
    console.log(success, message);
    setTrxnSucess(success);
    setErrorMessage(message);
    setGettingReceipt(false);
  };

  const handleAGENApprove = async (amount) => {
    setTrxnSucess(null);
    setGettingReceipt(true);
    const { success, message } = await handleApproveAGEN(amount);
    console.log(success, message);
    setTrxnSucess(success);
    setErrorMessage(message);
    setGettingReceipt(false);
  };

  useEffect(() => {
    if (trxnSucess === true) {
      const getData = async () => {
        await getaGENData();
        getGENBalance();
      };
      getData();
    }
  }, [trxnSucess]);

  return (
    <div className="section-earn-detail" name="401staking">
      <h3 className="heading align-self-center double-line">Mint and Redeem</h3>

      <div className="sub-section group justify-evenly">
        <div className="my-align-center">
          <h4 className="light">aGEN Balance</h4>
          <h5 className="highlight">
            {aGENStatus && aGENStatus.userBalance} aGEN
          </h5>
        </div>
        <div className="my-align-center">
          <h4 className="light">aGEN Fair Price</h4>
          <h5 className="highlight">$23.15</h5>
        </div>
        <div className="my-align-center">
          <h4 className="light">aGEN Price(Dex)</h4>
          <h5 className="highlight">$21.07</h5>
        </div>
      </div>
      <div className="sub-section">
        <Redeem
          aGENStatus={aGENStatus}
          handleMint={handleMint}
          handleRedeem={handleRedeem}
          handleGENApprove={handleGENApprove}
          handleAGENApprove={handleAGENApprove}
        />
        {gettingReceipt && (
          <>
            <div className="my-align-center">
              <Spinner className="text-center" type="border" color="info">
                {" "}
              </Spinner>
            </div>
          </>
        )}
        {trxnSucess !== null && (
          <Alert className="text-center" color={trxnSucess ? "info" : "danger"}>
            {errorMessage}
          </Alert>
        )}
      </div>
      <div className="sub-section text-center">
        <p>Mint Fee: {aGENStatus && aGENStatus.mintFeePercent} %</p>
        <p>Redeem Fee: {aGENStatus && aGENStatus.redeemFeePercent} %</p>
      </div>
    </div>
  );
}
export default AGENRedeem;
