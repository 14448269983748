import "../styles/base.css";
import "../styles/sections.css";

import { TbBookmarkQuestion } from "react-icons/tb";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Container, Row, Col, Button } from "reactstrap";
import { UseContractInfo } from "../web3/ContractContext";
import SwapUI from "./SwapUI";

export function MyBalances() {
  const { isConnected, genPrice, genBalance, genTotalSupply } =
    UseContractInfo();
  return (
    <>
      <div className="sub-section group justify-evenly">
        <div className="my-align-center">
          <h3>My balances</h3>
          <h3 className="highlight">
            {genBalance.toFixed(2)} GEN (${(genPrice * genBalance).toFixed(2)})
          </h3>
        </div>
        <div className="my-align-center">
          <h3>GEN Price</h3>
          <h3 className="highlight">${genPrice.toFixed(2)}</h3>
        </div>
      </div>
      <div className="section-divider"></div>
      {/* <div className="sub-section group justify-between">
        <h3>GEN</h3>
        <h3>
          <span className="highlight">
            {" "}
            {genBalance.toLocaleString("en-US", {
              maximumFractionDigits: 2,
            })}
          </span>{" "}
          GEN
        </h3>
      </div> */}
      <div className="sub-section group align-center">
        <TbBookmarkQuestion className="highlight" size={50} />
        <div className="ml-3 subgroup">
          <p className="mb-0">What is GEN?</p>
          <a
            className="mt-0 mb-0"
            target="_new"
            href="https://docs.thegeneratorproject.com/"
          >
            find out more...
          </a>
        </div>
      </div>
      <div className="sub-section group">
        <Button
          outline
          color=""
          className="btn-outline"
          size="lg"
          disabled={!isConnected}
        >
          <a
            target="_new"
            href="https://equalizer.exchange/swap?fromToken=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&toToken=0xd23F9118a0FeE308368c90d15F273c05f20c6A3d"
          >
            Get GEN <FaExternalLinkAlt className="ml-3" />
          </a>
        </Button>
      </div>
      <div className="section-divider"></div>
      <SwapUI />
    </>
  );
}
export default MyBalances;
