import "../styles/base.css";
import "../styles/sections.css";
import { Button, InputGroup, Input } from "reactstrap";
import { useState } from "react";
import { UseContractInfo } from "../web3/ContractContext";
import { FaExternalLinkAlt } from "react-icons/fa";

export function Bond(props) {
  const {
    isConnected,
    genBalance,
    usdcBalance,
    genftmLPBalance,
    lpBondStatus,
  } = UseContractInfo();
  const [amount, setAmount] = useState("");

  const setMaxAmount = async () => {
    if (props.bondToken === "USDC") {
      if (usdcBalance < 5000) setAmount(usdcBalance);
      else setAmount(5000);
    }
    if (props.bondToken === "LP") {
      if (genftmLPBalance < (lpBondStatus && lpBondStatus.maxPerTx))
        setAmount(genftmLPBalance);
      else setAmount(lpBondStatus && lpBondStatus.maxPerTx);
    }
  };
  return (
    <>
      {/* <div className="row"> */}
      <div className="group mb-3">
        <Button outline color="" className="btn-underline" size="lg">
          BOND
        </Button>
      </div>
      <div className="group mb-3">
        <InputGroup className="input-group">
          <Input
            id="stakeNumber"
            name="number"
            // placeholder="0"
            type="number"
            className="input-number"
            style={{ marginRight: 0, backgroundColor: "transparent" }}
            value={amount}
            disabled={!isConnected}
            onChange={(event) => {
              console.log(event.target.value);
              setAmount(event.target.value);
            }}
          />
          <Button
            color=""
            id="max"
            style={{ borderRadius: 0, color: "black" }}
            disabled={!isConnected}
            onClick={() => {
              setMaxAmount();
            }}
          >
            Max
          </Button>
        </InputGroup>
      </div>
      <p className="text-right">
        {props.bondToken === "USDC" ? (
          <>USDC Balance: {usdcBalance.toFixed(2)}</>
        ) : (
          <>LP Balance: {genftmLPBalance.toFixed(2)}</>
        )}
      </p>
      {(!props.bondStatus ||
        (props.bondStatus && props.bondStatus.bondOpen === false)) && (
        <div className="text-center">
          <h4 className="danger">Bond is closed</h4>
        </div>
      )}
      {/* </div> */}
      <div className="group mt-5 mb-3">
        <Button
          outline
          color=""
          className="btn-outline"
          size="lg"
          disabled={
            !isConnected ||
            !props.bondStatus ||
            (props.bondStatus && props.bondStatus.bondOpen === false)
          }
          onClick={() => {
            if (amount > 0 && amount <= 5000) props.handleBond(amount);
          }}
        >
          Bond
        </Button>

        <Button
          outline
          color=""
          className="btn-outline"
          size="lg"
          disabled={
            !isConnected ||
            !props.bondStatus ||
            (props.bondStatus && props.bondStatus.bondOpen === false) ||
            (props.userBondInfo && props.userBondInfo.claimableToken === 0)
          }
          onClick={() => {
            if (props.userBondInfo && props.userBondInfo.claimableToken > 0)
              props.handleBondClaim();
          }}
        >
          Claim
        </Button>
      </div>
      <div className="group mt-3 mb-3">
        <Button
          outline
          color=""
          className="btn-outline"
          size="lg"
          style={{ width: "100%" }}
          disabled={
            !isConnected ||
            !props.bondStatus ||
            (props.bondStatus && props.bondStatus.bondOpen === false)
          }
          onClick={() => {
            if (amount > 0 && amount <= 5000) props.handleApprove(amount);
          }}
        >
          Approve <FaExternalLinkAlt className="ml-3" />
        </Button>
      </div>
    </>
  );
}
export default Bond;
