import "../styles/base.css";
import "../styles/menu.css";
import { Button, FormGroup, InputGroup, Input, Label } from "reactstrap";
import { useEffect, useState } from "react";
import { UseContractInfo } from "../web3/ContractContext";

export function Calculator() {
  const { isConnected, genPrice, genBalance, genUserInfo, genStakingStatus } =
    UseContractInfo();

  const [changeRecorded, setChangeRecorded] = useState(false);
  const [investment, setInvestment] = useState(0);
  const [dailyAPR, setDailyAPR] = useState(0);
  const [entryGENPrice, setEntryGENPrice] = useState(genPrice);
  const [targetGENPrice, setTargetGENPrice] = useState(genPrice);
  const [sliderDays, setSliderDays] = useState(30);

  const [initialInvestment, setInitialInvestment] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [genReward, setGENReward] = useState(0);
  const [usdcReward, setUsdcReward] = useState(0);
  const [balanceTargetPrice, setBalanceTargetPrice] = useState(0);
  const [balanceWithReward, setBalanceWithReward] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);

  const handleCalculation = () => {
    console.log("handle Calculation");
    const initialInvest = investment * Number(entryGENPrice);
    setInitialInvestment(initialInvest);
    const veGENBal = genUserInfo ? genUserInfo.stakedBalance : 0;
    const currentBal =
      (Number(investment) + Number(veGENBal)) * Number(genPrice);
    console.log(investment, veGENBal, currentBal);
    setCurrentBalance(currentBal);
    const genRe = Number(dailyAPR) * sliderDays * Number(investment);
    setGENReward(genRe);
    const usdRe = genRe * entryGENPrice;
    setUsdcReward(usdRe);
    const balTarget = (Number(investment) + veGENBal) * Number(targetGENPrice);
    setBalanceTargetPrice(balTarget);
    const balWithReward = balTarget + usdRe;
    setBalanceWithReward(balWithReward);
    const profit = balWithReward - currentBal;
    setTotalProfit(profit);
    setChangeRecorded(false);
  };

  useEffect(() => {
    if (changeRecorded) handleCalculation();
  }, [changeRecorded]);

  useEffect(() => {
    if (genStakingStatus) {
      // console.log(genStakingStatus.dailyAPR);
      setDailyAPR(genStakingStatus.dailyAPR);
    }
  }, [genStakingStatus]);

  useEffect(() => {
    if (genUserInfo) {
      setCurrentBalance(genUserInfo.stakedBalance);
    }
  }, [genUserInfo]);

  return (
    <div className="section-earn-detail" name="401staking">
      <h3 className="heading align-self-center double-line">Calculator</h3>

      <div className="sub-section group justify-evenly">
        <div className="my-align-center">
          <h4 className="light">GEN Price</h4>
          <h5 className="highlight">${genPrice}</h5>
        </div>
        <div className="my-align-center">
          <h4 className="light">APR</h4>
          <h5 className="highlight">
            {genStakingStatus && genStakingStatus.dailyAPR.toFixed(2)}%
          </h5>
        </div>
        <div className="my-align-center">
          <h4 className="light">veGEN Balance</h4>
          <h5 className="highlight">
            {genUserInfo && genUserInfo.stakedBalance} veGEN
          </h5>
        </div>
      </div>
      <div className="sub-section">
        <div className="group justify-between">
          <FormGroup
            style={{ width: "50%", paddingLeft: 10, paddingRight: 10 }}
          >
            <Label className="ml-2" for="exampleEmail">
              veGEN Amount
            </Label>
            <Input
              type="number"
              className="input-group"
              style={{ marginRight: 0 }}
              value={investment}
              onChange={(e) => {
                setInvestment(e.target.value);
                setChangeRecorded(true);
              }}
            />
          </FormGroup>
          <FormGroup
            style={{ width: "50%", paddingLeft: 10, paddingRight: 10 }}
          >
            <Label className="ml-2" for="exampleEmail">
              Daily APR
            </Label>
            <Input
              type="number"
              className="input-group"
              style={{ marginRight: 0 }}
              value={dailyAPR.toFixed(2)}
              onChange={(e) => {
                setDailyAPR(e.target.value);
                setChangeRecorded(true);
              }}
            />
          </FormGroup>
        </div>
        <div className="group justify-between">
          <FormGroup
            style={{ width: "50%", paddingLeft: 10, paddingRight: 10 }}
          >
            <Label className="ml-2" for="exampleEmail">
              Entry GEN Price
            </Label>
            <Input
              type="number"
              className="input-group"
              style={{ marginRight: 0 }}
              value={entryGENPrice}
              onChange={(e) => {
                setEntryGENPrice(e.target.value);
                setChangeRecorded(true);
              }}
            />
          </FormGroup>
          <FormGroup
            style={{ width: "50%", paddingLeft: 10, paddingRight: 10 }}
          >
            <Label className="ml-2" for="exampleEmail">
              Target GEN Price
            </Label>
            <Input
              type="number"
              className="input-group"
              style={{ marginRight: 0 }}
              value={targetGENPrice}
              onChange={(e) => {
                setTargetGENPrice(e.target.value);
                setChangeRecorded(true);
              }}
            />
          </FormGroup>
        </div>
        <div className="mt-3 group text-center">
          <FormGroup
            className="subgroup"
            style={{ width: "100%", paddingLeft: 10, paddingRight: 10 }}
          >
            <Label className="ml-2 text-left" for="exampleEmail">
              {sliderDays} days
            </Label>
            <Input
              id="exampleRange"
              name="range"
              type="range"
              style={{ width: "90%", marginLeft: 10, marginRight: 10 }}
              onChange={(e) => {
                setSliderDays(e.target.value);
                setChangeRecorded(true);
              }}
            />
          </FormGroup>
        </div>
      </div>
      <div className="sub-section">
        <div className="row justify-between light">
          <div className="text-left">
            <p>Your Initial Investment</p>
            <p>Current Balance</p>
            <p>GEN Staking Rewards</p>
            <p>Balance At Target Price</p>
            <p>Total Balance + Rewards</p>
            <p>Total Profit</p>
          </div>
          <div className="text-right">
            <p>$ {initialInvestment.toFixed(2)}</p>
            <p>$ {currentBalance.toFixed(2)}</p>
            <p>
              {genReward.toFixed(2)} GEN ($ {usdcReward.toFixed(2)})
            </p>
            <p>$ {balanceTargetPrice.toFixed(2)}</p>
            <p>$ {balanceWithReward.toFixed(2)}</p>
            <p>$ {totalProfit.toFixed(2)}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Calculator;
