import "../styles/base.css";
import "../styles/sections.css";
import { Container, Row, Col, Button } from "reactstrap";
import { UseContractInfo } from "../web3/ContractContext";
import { useState } from "react";

export function Dashboard() {
  const { isConnected, dashBoardData } = UseContractInfo();

  return (
    <>
      <h2 className="mt-3 align-self-start">Dashboard</h2>

      <div className="sub-section group justify-evenly">
        {/* <div className="my-align-center"> */}
        <div className="subgroup">
          <h5 className="light">GEN Price</h5>
          <h5 className="highlight mb-5"></h5>
          {/* </div>

        <div className="subgroup "> */}

          <h5 className="light">TVL</h5>
          <h5 className="highlight mb-5"></h5>
          {/* </div>

        <div className="subgroup "> */}

          <h5 className="light">% of Supply Staked</h5>
          <h5 className="highlight mb-5">
            {dashBoardData && dashBoardData.percentStaked.toFixed(2)} %
          </h5>
        </div>

        {/* </div> */}

        {/* <div className="my-align-center"> */}
        <div className="subgroup ">
          <h5 className="light">GEN Circulating</h5>
          <h5 className="highlight mb-5">
            373,353 /{" "}
            {dashBoardData &&
              dashBoardData.totalSupply.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              })}
          </h5>
          {/* </div>

        <div className="subgroup "> */}

          <h5 className="light">APR</h5>
          <h5 className="highlight mb-5">
            {dashBoardData && dashBoardData.annualAPR.toFixed(2)} %
          </h5>
          {/* </div>

        <div className="subgroup "> */}

          <h5 className="light">Total GEN Burned</h5>
          <h5 className="highlight mb-5"></h5>
          {/* </div>

        <div className="subgroup "> */}

          <h5 className="light">Debase Ratio</h5>
          <h5 className="highlight mb-5">
            {dashBoardData && dashBoardData.rebaseRatio}
          </h5>
        </div>

        {/* </div> */}

        {/* <div className="my-align-center"> */}
        <div className="subgroup ">
          <h5 className="light">Market Cap</h5>
          <h5 className="highlight mb-5"></h5>
          {/* </div>

        <div className="subgroup"> */}
          <h5 className="light">Treasury Value</h5>
          <h5 className="highlight mb-5"></h5>
          {/* </div>

        <div className="subgroup "> */}

          <h5 className="light">Total LP Burned</h5>
          <h5 className="highlight mb-5"></h5>
        </div>

        {/* </div> */}
      </div>
    </>
  );
}
export default Dashboard;
