import { Container, Row, Col, Button } from "reactstrap";
import Dashboard from "../sections/Dashboard";
import MyBalances from "../sections/MyBalances";
import Menu from "../sections/Menu";
import Earn from "../sections/Earn";
import "../styles/base.css";
import { useState } from "react";

export function Home() {
  return (
    // <div className="base-container">
    <Container fluid className="base-container">
      <Row className="row section-earn">
        <Col className="pl-5 col-5 section-container">
          <Earn />
        </Col>
      </Row>
      <Row className="row justify-left">
        <Col className="col-5 section-container section-balance">
          <MyBalances />
        </Col>
        <Col className="col-2 section-container section-menu">
          <Menu />
        </Col>
        {/* <Col className="section-container section-japas">4</Col> */}
      </Row>
      <Row className="row justify-left">
        <Col className="col-6 section-container section-dashboard">
          <Dashboard />
        </Col>
        <Col className="col-1 section-container section-japas"></Col>
      </Row>
    </Container>
    // </div>
  );
}
export default Home;
