import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
} from "react-router-dom";

import GENStaking from "../menu/genstaking";
import GENFTMBond from "../menu/genftmbond";
import Calculator from "../menu/calculator";
import LPStaking from "../menu/lpstaking";
import USDCBond from "../menu/usdcbond";
import AGENRedeem from "../menu/agenreedem";
import AGENEarn from "../menu/agenearn";
import "../styles/base.css";
import "../styles/sections.css";

export function Earn(props) {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <h5 className="mt-10">Click on a menu item to get started</h5>
          </>
        }
      ></Route>

      <Route path="/genstaking" element={<GENStaking />}></Route>
      <Route path="/lpstaking" element={<LPStaking />}></Route>
      <Route path="/usdcbond" element={<USDCBond />}></Route>
      <Route path="/genftmbond" element={<GENFTMBond />}></Route>
      <Route path="/calculator" element={<Calculator />}></Route>
      <Route path="/agenredeem" element={<AGENRedeem />}></Route>
      <Route path="/agenearn" element={<AGENEarn />}></Route>
      <Route component={Error} />
    </Routes>
  );
}
export default Earn;
