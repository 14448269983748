import "../styles/base.css";
import "../styles/sections.css";
import "../styles/menu.css";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FaStackOverflow } from "react-icons/fa";
import { FaCalculator } from "react-icons/fa6";
import { TbPigMoney } from "react-icons/tb";

export function Menu(props) {
  return (
    <>
      <h3 className="mt-3 mb-3 px-2 underline">Menu</h3>

      <div className="menu-item mb-">
        <NavLink
          className="menu-group item-link light"
          activeclassname="active"
          // onClick={() => props.setActiveMenuItem("401staking")}
          to="/genstaking"
        >
          <FaStackOverflow size={25} style={{ marginRight: 5 }} /> GEN Staking
          Plan
        </NavLink>
      </div>
      <div className="menu-item mb-2">
        <NavLink
          className="menu-group item-link light"
          activeclassname="active"
          // onClick={() => props.setActiveMenuItem("lpstaking")}
          to="/lpstaking"
        >
          <FaStackOverflow size={25} style={{ marginRight: 5 }} /> Lp Staking
          Equity
        </NavLink>
      </div>
      <div className="menu-item mb-2">
        <NavLink
          className="menu-group item-link light"
          activeclassname="active"
          to="/usdcbond"
          // onClick={() => props.setActiveMenuItem("usdcbond")}
        >
          <TbPigMoney size={20} style={{ marginRight: 5 }} />
          USDC Bond
        </NavLink>
      </div>
      <div className="menu-item mb-2">
        <NavLink
          className="menu-group item-link light"
          activeclassname="active"
          // onClick={() => props.setActiveMenuItem("GENethbond")}
          to="/genftmbond"
        >
          <TbPigMoney size={20} style={{ marginRight: 5 }} />
          GEN-FTM Bond
        </NavLink>
      </div>
      <div className="menu-item mb-2 ">
        <NavLink
          className="menu-group item-link light"
          activeclassname="active"
          // onClick={() => props.setActiveMenuItem("calculator")}
          to="/calculator"
        >
          <FaCalculator size={20} style={{ marginRight: 5 }} />
          Calculator
        </NavLink>
      </div>
      <div className="menu-item mb-2 ">
        <NavLink
          className="menu-group item-link light"
          activeclassname="active"
          // onClick={() => props.setActiveMenuItem("calculator")}
          to="/agenredeem"
        >
          <FaCalculator size={20} style={{ marginRight: 5 }} />
          aGEN Redeem
        </NavLink>
      </div>
      {/* <div className="menu-item mb-2 ">
        <NavLink
          className="menu-group item-link light"
          activeclassname="active"
          // onClick={() => props.setActiveMenuItem("calculator")}
          to="/agenearn"
        >
          <FaCalculator size={20} style={{ marginRight: 5 }} />
          aGEN Earn
        </NavLink>
      </div> */}
    </>
  );
}
export default Menu;
