import "../styles/base.css";
import "../styles/sections.css";
import { Button, InputGroup, Input, Label } from "reactstrap";
import { useState } from "react";
import { UseContractInfo } from "../web3/ContractContext";
import { FaExternalLinkAlt } from "react-icons/fa";

export function Stake(props) {
  const { isConnected, genBalance, genftmLPBalance } = UseContractInfo();
  const withdrawable = props.stakingStatus && props.stakingStatus.withdrawable;

  const [selected, setSelected] = useState("stake");
  const [amount, setAmount] = useState("");

  const setMaxAmount = async () => {
    if (props.stakingToken === "GEN") {
      if (selected === "stake") {
        setAmount(genBalance);
      } else {
        if (props.userStakeStatus) {
          console.log(props.userStakeStatus.stakedBalance);
          setAmount(props.userStakeStatus.stakedBalance);
        }
      }
    }
    if (props.stakingToken === "LP") {
      if (selected === "stake") {
        setAmount(genftmLPBalance);
      } else {
        if (props.userStakeStatus) {
          console.log(props.userStakeStatus.stakedBalance);
          setAmount(props.userStakeStatus.stakedBalance);
        }
      }
    }
  };

  return (
    <>
      {/* <div className="row"> */}
      <div className="group mb-3">
        <Button
          outline
          color=""
          className={selected === "stake" ? "btn-underline" : "btn-text"}
          size="lg"
          onClick={() => {
            setSelected("stake");
            setAmount("");
          }}
        >
          STAKE
        </Button>
        <Button
          outline
          color=""
          className={selected === "unstake" ? "btn-underline" : "btn-text"}
          size="lg"
          onClick={() => {
            if (withdrawable !== false) setSelected("unstake");
            setAmount("");
          }}
        >
          UNSTAKE
        </Button>
      </div>
      <div className="group mb-3">
        <InputGroup className="input-group">
          <Input
            id="stakeNumber"
            name="number"
            placeholder="0"
            type="number"
            className="input-number"
            style={{
              marginRight: 0,
              backgroundColor: "transparent",
            }}
            min="0"
            value={amount}
            disabled={!isConnected}
            onChange={(event) => {
              console.log(event.target.value);
              setAmount(event.target.value);
            }}
          />
          <Button
            color=""
            id="max"
            style={{ borderRadius: 0, color: "black" }}
            disabled={!isConnected}
            onClick={() => {
              setMaxAmount();
            }}
          >
            Max
          </Button>
        </InputGroup>
      </div>
      <p className="text-right">
        {selected === "stake" ? (
          <>
            {props.stakingToken === "GEN" ? (
              <>GEN Balance: {genBalance.toFixed(2)}</>
            ) : (
              <>LP Balance: {genftmLPBalance.toFixed(2)}</>
            )}
          </>
        ) : (
          <>Staked Balance : {props.userStakeStatus.stakedBalance.toFixed(2)}</>
        )}
      </p>
      {!props.stakingStatus ? (
        <div className="text-center">
          <h4 className="danger">Pool is closed</h4>
        </div>
      ) : (
        <>
          {/* {withdrawable === false && (
            <div className="text-center">
              <h5 className="danger">Unstake is closed</h5>
            </div>
          )} */}
        </>
      )}

      {/* </div> */}
      <div className="group mt-5 mb-3">
        {selected === "stake" ? (
          <Button
            outline
            color=""
            className="btn-outline"
            size="lg"
            disabled={!isConnected || !props.stakingStatus}
            onClick={() => {
              if (amount > 0) props.handleStake(amount);
            }}
          >
            Stake
          </Button>
        ) : (
          <Button
            outline
            color=""
            className="btn-outline"
            size="lg"
            disabled={!isConnected || !props.stakingStatus}
            onClick={() => {
              if (amount > 0) props.handleUnStake(amount);
            }}
          >
            UnStake
          </Button>
        )}

        {/* <Button
          outline
          color=""
          className="btn-outline"
          size="lg"
          disabled={!isConnected || !props.stakingStatus}
        >
          Claim
        </Button> */}
      </div>

      <div className="group mt-3 mb-3">
        {selected === "stake" && (
          <Button
            outline
            color=""
            className="btn-outline"
            size="lg"
            style={{ width: "100%" }}
            disabled={!isConnected || !props.stakingStatus}
            onClick={() => {
              if (amount > 0) props.handleApprove(amount);
            }}
          >
            Approve <FaExternalLinkAlt className="ml-3" />
          </Button>
        )}
      </div>
    </>
  );
}
export default Stake;
