import { Button, ButtonGroup } from "reactstrap";
import "../styles/base.css";
import "../styles/sections.css";
import logo from "../images/Generator+Logo.png";
import tg from "../images/tglogo.svg";
import twitter from "../images/twitterlogo.svg";
function Head() {
  return (
    <div className="header-container container-fluid">
      {/* <h1 className="protest-guerrilla-regular">Crypto</h1> */}
      <div className="group justify-left align-bottom logo-group">
        <img className="image-logo" src={logo} />
        <h3 className="teko-bold">Project</h3>
        {/* <a className="btn-outline">Discord</a> */}
      </div>
      <div className="button-group">
        <a
          className="btn-outline-dark"
          target="_new"
          href="https://twitter.com/Generator_fi"
        >
          <img className="image-logo-social" src={twitter} />
        </a>
        <a
          className="btn-outline-dark"
          target="_new"
          href="https://t.me/TheGeneratorProject"
        >
          <img className="image-logo-social" src={tg} />
        </a>
        <a
          className="btn-outline-dark"
          target="_new"
          href="https://docs.thegeneratorproject.com/"
        >
          Docs
        </a>
      </div>
      <div>
        <div className="connect-wrapper">
          <w3m-network-switch />
          <w3m-button />
        </div>
      </div>
    </div>
  );
}
export default Head;
