import "../styles/base.css";
import "../styles/menu.css";
import { Button } from "reactstrap";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Redeem } from "../components/redeem";

export function AGENEarn() {
  return (
    <div className="section-earn-detail" name="401staking">
      <h3 className="heading align-self-center double-line">Mint and Redeem</h3>

      <div className="sub-section group justify-evenly">
        <div className="my-align-center">
          <h4 className="light">Estimated Fees APR</h4>
          <h5 className="highlight">50-150 %</h5>
        </div>
        <div className="my-align-center">
          <h4 className="light">Estimated rewards APR</h4>
          <h5 className="highlight">250-400 %</h5>
        </div>
        <div className="my-align-center">
          <h4 className="light">Rewards per day</h4>
          <h5 className="highlight">50 aGEN (1226.5$)</h5>
        </div>
      </div>
      <div className="sub-section">
        <div className="group mt-3 mb-3">
          <Button
            outline
            color=""
            className="btn-outline"
            size="lg"
            style={{ width: "100%" }}
          >
            Provide liquidity <FaExternalLinkAlt className="ml-3" />
          </Button>
        </div>
        <div className="group mb-3">
          <Button
            outline
            color=""
            className="btn-outline"
            size="lg"
            style={{ width: "100%" }}
          >
            Check your position <FaExternalLinkAlt className="ml-3" />
          </Button>
        </div>
      </div>
      <div className="sub-section text-center">
        <p>Note 1: aBTC Lps don't get debased! and there is no fees!</p>
        <p>
          Note 2: Out of range positions don't receive fees. The more fees you
          generate from your position, the more rewards you receive
        </p>
      </div>
    </div>
  );
}
export default AGENEarn;
