import "../styles/base.css";
import "../styles/menu.css";
import Bond from "../components/bond";
import { Button, Alert } from "reactstrap";
import { UseContractInfo } from "../web3/ContractContext";
import React, { useState, useEffect, useContext } from "react";
import { Spinner } from "reactstrap";

export function USDCBond() {
  const {
    isConnected,
    getGENBalance,
    getUSDCBalance,
    genBalance,
    usdcBalance,
    getUSDCBondData,
    usdcBondStatus,
    getUSDCBondUserInfo,
    usdcBondUserInfo,
    handleBondUSDC,
    handleClaimBondUSDC,
    handleApproveUSDC,
  } = UseContractInfo();

  const [trxnSucess, setTrxnSucess] = useState(null);
  const [gettingReceipt, setGettingReceipt] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleUSDCBond = async (amount) => {
    if (usdcBondStatus.bondOpen) {
      setTrxnSucess(null);
      setGettingReceipt(true);
      const { success, message } = await handleBondUSDC(amount);
      console.log(success, message);
      setTrxnSucess(success);
      setErrorMessage(message);
      setGettingReceipt(false);
    }
  };
  const handleUSDCBondClaim = async (amount) => {
    setTrxnSucess(null);
    setGettingReceipt(true);
    const { success, message } = await handleClaimBondUSDC(amount);
    console.log(success, message);
    setTrxnSucess(success);
    setErrorMessage(message);
    setGettingReceipt(false);
  };
  const handleUSDCApprove = async (amount) => {
    setTrxnSucess(null);
    setGettingReceipt(true);
    const { success, message } = await handleApproveUSDC(amount);
    console.log(success, message);
    setTrxnSucess(success);
    setErrorMessage(message);
    setGettingReceipt(false);
  };

  useEffect(() => {
    if (trxnSucess === true) {
      const getData = async () => {
        await getUSDCBondData();
        await getUSDCBondUserInfo();
        await getGENBalance();
        await getUSDCBalance();
      };
      getData();
    }
  }, [trxnSucess]);

  return (
    <div className="section-earn-detail" name="401staking">
      <h3 className="heading align-self-center double-line">USDC BOND</h3>

      <div className="sub-section group justify-evenly">
        <div className="my-align-center">
          <h4 className="light">Discount</h4>
          <h5 className="highlight">-57.65%</h5>
        </div>
        <div className="my-align-center">
          <h4 className="light">Bond Price</h4>
          <h5 className="highlight">
            {usdcBondStatus && usdcBondStatus.bondPrice.toFixed(2)}
          </h5>
        </div>
        <div className="my-align-center">
          <h4 className="light">Remaining Bonds</h4>
          <h5 className="highlight">
            {usdcBondStatus && usdcBondStatus.remainingBond.toFixed(2)} GEN
          </h5>
        </div>
      </div>
      <div className="sub-section">
        <Bond
          bondToken="USDC"
          bondStatus={usdcBondStatus}
          userBondInfo={usdcBondUserInfo}
          handleBond={handleUSDCBond}
          handleBondClaim={handleUSDCBondClaim}
          handleApprove={handleUSDCApprove}
        />
        {gettingReceipt && (
          <>
            <div className="my-align-center">
              <Spinner className="text-center" type="border" color="info">
                {" "}
              </Spinner>
            </div>
          </>
        )}
        {trxnSucess !== null && (
          <Alert className="text-center" color={trxnSucess ? "info" : "danger"}>
            {errorMessage}
          </Alert>
        )}
      </div>
      <div className="sub-section text-center">
        <p>
          Note 1: Tokens are vested linearly over{" "}
          {usdcBondStatus && usdcBondStatus.vestingTime} days.
        </p>
        <p>
          Note 2: If you bond the 2nd time, your vested claimable bond will be
          revested. Tip: claim before bond again.
        </p>
        <p>Note 3: Max 5000 USDC per tx.</p>
      </div>
      <div className="sub-section">
        <div className="row justify-between light">
          <div className="text-left">
            <p>Your USDC Balance</p>
            <p>Your bonded amount</p>
            <p>GEN vested</p>
            <p>Vesting remaining</p>
          </div>
          <div className="text-right">
            <p>{usdcBalance.toFixed(2)} USDC</p>
            <p>
              {usdcBondUserInfo && usdcBondUserInfo.totalBonded.toFixed(2)} GEN
            </p>
            <p>
              {usdcBondUserInfo && usdcBondUserInfo.claimableToken.toFixed(2)}{" "}
              GEN
            </p>
            <p>
              {usdcBondUserInfo &&
                (
                  usdcBondUserInfo.totalBonded - usdcBondUserInfo.claimableToken
                ).toFixed(2)}{" "}
              GEN
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default USDCBond;
