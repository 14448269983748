import "../styles/base.css";
import "../styles/menu.css";
import Bond from "../components/bond";
import { Button, Alert } from "reactstrap";
import { FaExternalLinkAlt } from "react-icons/fa";
import { UseContractInfo } from "../web3/ContractContext";
import React, { useState, useEffect, useContext } from "react";
import { Spinner } from "reactstrap";

export function GENFTMBond() {
  const {
    isConnected,
    getGENBalance,
    genBalance,
    genftmLPBalance,
    getLPBalance,
    getLPBondData,
    lpBondStatus,
    getLPBondUserInfo,
    lpBondUserInfo,
    handleBondLP,
    handleClaimBondLP,
    handleApproveLPforBond,
  } = UseContractInfo();

  const [trxnSucess, setTrxnSucess] = useState(null);
  const [gettingReceipt, setGettingReceipt] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleLPBond = async (amount) => {
    if (lpBondStatus.bondOpen) {
      setTrxnSucess(null);
      setGettingReceipt(true);
      const { success, message } = await handleBondLP(amount);
      console.log(success, message);
      setTrxnSucess(success);
      setErrorMessage(message);
      setGettingReceipt(false);
    }
  };
  const handleLPBondClaim = async (amount) => {
    setTrxnSucess(null);
    setGettingReceipt(true);
    const { success, message } = await handleClaimBondLP(amount);
    console.log(success, message);
    setTrxnSucess(success);
    setErrorMessage(message);
    setGettingReceipt(false);
  };
  const handleLPApprove = async (amount) => {
    setTrxnSucess(null);
    setGettingReceipt(true);
    const { success, message } = await handleApproveLPforBond(amount);
    console.log(success, message);
    setTrxnSucess(success);
    setErrorMessage(message);
    setGettingReceipt(false);
  };

  useEffect(() => {
    if (trxnSucess === true) {
      const getData = async () => {
        await getLPBondData();
        await getLPBondUserInfo();
        await getGENBalance();
        await getLPBalance();
      };
      getData();
    }
  }, [trxnSucess]);

  return (
    <div className="section-earn-detail" name="401staking">
      <h3 className="heading align-self-center double-line">GENFTM LP BOND</h3>

      <div className="sRub-section group justify-evenly">
        <div className="my-align-center">
          <h4 className="light">Discount</h4>
          <h5 className="highlight">
            {lpBondStatus && lpBondStatus.discount.toFixed(1)}%
          </h5>
        </div>
        <div className="my-align-center">
          <h4 className="light">Bond Price</h4>
          <h5 className="highlight">
            {lpBondStatus && lpBondStatus.bondPrice.toFixed(2)}
          </h5>
        </div>
        <div className="my-align-center">
          <h4 className="light">Remaining Bonds</h4>
          <h5 className="highlight">
            {lpBondStatus && lpBondStatus.remainingBond.toFixed(2)} GEN
          </h5>
        </div>
      </div>
      <div className="sub-section">
        <Bond
          bondToken="LP"
          bondStatus={lpBondStatus}
          userBondInfo={lpBondUserInfo}
          handleBond={handleLPBond}
          handleBondClaim={handleLPBondClaim}
          handleApprove={handleLPApprove}
        />
        <div className="group mb-3">
          <Button
            outline
            color=""
            className="btn-outline"
            size="lg"
            style={{ width: "100%" }}
          >
            Get LP Token <FaExternalLinkAlt className="ml-3" />
          </Button>
        </div>
        {gettingReceipt && (
          <>
            <div className="py-3 my-align-center">
              <Spinner className="text-center" type="border" color="info">
                {" "}
              </Spinner>
            </div>
          </>
        )}
        {trxnSucess !== null && (
          <Alert className="text-center" color={trxnSucess ? "info" : "danger"}>
            {errorMessage}
          </Alert>
        )}
      </div>
      <div className="sub-section text-center">
        <p>
          Note 1: Tokens are vested linearly over{" "}
          {lpBondStatus && lpBondStatus.vestingTime}days.
        </p>
        <p>
          Note 2: If you bond the 2nd time, your vested claimable bond will be
          revested. Tip: claim before bond again.
        </p>
      </div>
      <div className="sub-section">
        <div className="row justify-between light">
          <div className="text-left">
            <p>Your GENFTM LP Balance</p>
            <p>Your bonded amount</p>
            <p>GEN vested</p>
            <p>Vesting remaining</p>
          </div>
          <div className="text-right">
            <p>{genftmLPBalance.toFixed(2)} GEN-FTM LP</p>
            <p>{lpBondUserInfo && lpBondUserInfo.totalBonded.toFixed(2)} GEN</p>
            <p>
              {lpBondUserInfo && lpBondUserInfo.claimableToken.toFixed(2)} GEN
            </p>
            <p>
              {lpBondUserInfo &&
                (
                  lpBondUserInfo.totalBonded - lpBondUserInfo.claimableToken
                ).toFixed(2)}{" "}
              GEN
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default GENFTMBond;
